<template>
  <div class="text-xs-center text-right">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on"
          ><v-icon left>library_add</v-icon> Adicionar Quantidade de Alinhadores
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary white--text" primary-title>
          Adicionar Opção de Alinhadores
        </v-card-title>

        <v-card-text>
          <v-container grid-list-sm class="pa-4">
            <v-form ref="alinhador" v-model="valid" lazy-validation>
              <v-layout row wrap>
                <v-flex xs12 align-center justify-space-between>
                  <v-layout align-center>
                    <v-text-field
                      :label="$t('Quantidade de Alinhadores Superior')"
                      type="number"
                      hide-spin-buttons
                      hide-details
                      single-line
                      persistent-hint
                      :placeholder="
                        $t(
                          'Digite a quantidade de alinhadores superior sugerida'
                        )
                      "
                      v-model="alinhador.quantidade_superior"
                    ></v-text-field>
                  </v-layout>
                  <v-layout align-center>
                    <v-text-field
                      :label="$t('Quantidade de Alinhadores Inferior')"
                      type="number"
                      hide-spin-buttons
                      hide-details
                      single-line
                      persistent-hint
                      :placeholder="
                        $t(
                          'Digite a quantidade de alinhadores Inferior sugerida'
                        )
                      "
                      v-model="alinhador.quantidade_inferior"
                    ></v-text-field>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="salvar">{{
            $t("Inserir")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      dialog: false,
      alinhador: {},
      valid:false,
    };
  },
  methods: {
    salvar() {
      let alinhador = this.alinhador;
      let planejamento = this.planejamento;
      if (planejamento.id) {
        toastr.success(
          "Inserindo quantidade de alinhadores...",
          "AlignerPlan"
        );
        this.$http
          .post(
            this.$store.state.urlBase +
              "planejamentos/alinhador/" +
              planejamento.id,
            alinhador
          )
          .then((response) => {
            window.getApp.$emit("APP_PLANOS_UPDATED");
            toastr.success(
              "Quantidade Inserida com sucesso...",
              "AlignerPlan"
            );
            this.$refs.alinhador.reset()
            this.dialog = false;
          });
      } else {
        toastr.error(
          "Não é possível inserir quantidade para este planejamento...",
          "AlignerPlan"
        );
      }
    },
  },
  props: {
    planejamento: {},
  },
};
</script>
