<template>
  <v-dialog v-model="$store.state.dialogPlanejamento" persistent fullscreen hide-overlay
    transition="dialog-bottom-transition">
    <v-btn slot="activator" color="primary" dark v-if="novo" @click.native="novoPlanejamento">
      <v-icon left>add_circle_outline</v-icon>
      Novo Planejamento
    </v-btn>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="atualizar">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline">Planejamento
          <span v-if="$store.state.planejamento.status">- {{ $store.state.planejamento.status }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn flat dark @click.native="salvar" v-if="isUndefined($store.state.planejamento.id)">Salvar
          </v-btn>
          <v-btn flat dark @click.native="update" v-else>Atualizar</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-layout wrap v-if="
                $store.state.planejamento.status ===
                'Devolvido para o planejamento'
              ">
                <v-flex xs12>
                  <v-card color="warning darken-2" class="white--text">
                    <v-card-title primary-title>
                      <div>
                        <div class="headline">Motivo da Devolução:</div>
                        <span class="mt-5">{{
                            $store.state.planejamento.motivo_devolucao
                        }}</span>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap v-if="
                $store.state.planejamento.status === 'Devolvido para Revisão'
              ">
                <v-flex xs12>
                  <v-card color="warning darken-2" class="white--text">
                    <v-card-title primary-title>
                      <div>
                        <div class="headline">Motivo da Devolução:</div>
                        <span class="mt-5">{{
                            $store.state.planejamento.motivo
                        }}</span>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-if="!isUndefined($store.state.planejamento.id)"
                    :disabled="!isUndefined($store.state.planejamento.id)" label="ID *"
                    v-model="$store.state.planejamento.id"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field label="Link *" v-model="$store.state.planejamento.link"
                    hint="Informe a url inicial para gerar o link do visor."></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field label="Paciente" v-model="$store.state.planejamento.paciente"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea name="input-7-1" label="Parecer" value="" v-model="$store.state.planejamento.parecer"
                    hint="Parecer sobre os casos"></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field :label="$t('Relatório')" prepend-icon="attach_file"
                    v-model="$store.state.planejamento.relatorio" hint="Link para o relatório"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field label="Descrição do Relatório" v-model="$store.state.planejamento.descricaoRelatorio">
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field label="Pasta Virtual" hint="Link para a pasta virtual onde serão incluídos os arquivos"
                    v-model="$store.state.planejamento.repositorio"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field label="Descrição da Pasta Virtual"
                    v-model="$store.state.planejamento.descricaoRepositorio"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <!-- <v-text-field
                    label="Data da Entrega"
                    disabled
                    v-model="$store.state.planejamento.data_entrega"
                  ></v-text-field> -->
                  <v-menu ref="data_entrega" v-model="menu1" :close-on-content-click="false" :nudge-right="40" lazy
                    transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-model="computedDateFormatted" :label="$t('Data de Entrega')"
                        hint="DD/MM/YYYY formato" persistent-hint prepend-icon="event" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="$store.state.planejamento.data_entrega" locale="pt"
                      :min="new Date().toISOString().substr(0, 10)" no-title @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm12 md12 mb-5 v-if="
                  $store.getters.user.tipo !== 4 &&
                  $store.getters.user.tipo !== 2
                ">
                  <!-- <v-text-field
                    label="Planejador ID"
                    v-model="$store.state.planejamento.planejador_id"
                  ></v-text-field> -->
                  <v-autocomplete v-model="$store.state.planejamento.planejador_id" :hint="$t('Escolha o planejador')"
                    :items="$store.state.planejadores" item-text="name" item-value="id" :label="$t('Planejador')"
                    persistent-hint prepend-icon="account_circle">
                  </v-autocomplete>
                  <!-- <v-text-field
                    label="Planejador"
                    v-model="$store.state.planejamento.planejador_id"
                  ></v-text-field> -->
                </v-flex>
                <v-flex xs12 sm12 md6 v-if="$store.state.planejamento.id">
                  <v-input :messages="[
                    toDateComplete($store.state.planejamento.updated_at),
                  ]" prepend-icon="update">
                    Última Atualização
                  </v-input>
                </v-flex>
                <v-flex xs12 sm12 md6 v-if="$store.state.planejamento.id">
                  <v-input :messages="[
                    toDateComplete($store.state.planejamento.created_at),
                  ]" prepend-icon="more_time">
                    Data de Criação
                  </v-input>
                </v-flex>
              </v-layout>
              <!-- Arquivos -->
              <v-layout>
                <v-flex xs12 sm12 md12>
                  <v-toolbar flat color="white" class="px-0">
                    <v-toolbar-title>Arquivos STL Vinculados</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <arquivo class="text-right" :planejamento="$store.state.planejamento" v-if="!$store.state.planejamento.planejamento_id &&
                      $store.state.planejamento.origem === 'Centro de Planejamento' &&
                      user.tipo !== 2
                    "></arquivo>
                  </v-toolbar>
                  <v-data-table :headers="complexArquivos.headers" :search="search" :no-data-text="$t('noDataText')"
                    :items="complexArquivos.items" :rows-per-page-items="[
                      10,
                      25,
                      50,
                      { text: 'Todos', value: -1 },
                    ]" rows-per-page-text="Linhas por página" class="elevation-1" item-key="name"
                    v-model="complexArquivos.selected">
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.id }}</td>
                      <td>{{ props.item.nome }}</td>
                      <td>{{ props.item.tamanho }}</td>
                      <td>{{ props.item.tipo }}</td>
                      <td>{{ props.item.path }}</td>
                      <td>
                        {{
                            props.item.created_at | formatDate("DD/MM/Y H:m:s")
                        }}
                      </td>
                      <td>
                        <v-btn color="primary" depressed outline icon fab dark flat small @click="download(props.item)">
                          <v-icon>cloud_download</v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
              <!-- <p>{{$store.state.planejamento.origem}}</p> -->
              <!-- Alinhadores -->
              <v-layout v-if="
                $store.state.planejamento.origem === 'Centro de Planejamento'
              ">
                <v-flex xs12 sm12 md12>
                  <v-toolbar flat color="white" class="px-0">
                    <v-toolbar-title>Planejamento - Alinhadores</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <alinhador v-if="$store.state.planejamento.status !== 'Aprovado'"
                      :planejamento="$store.state.planejamento"></alinhador>
                  </v-toolbar>
                  <v-data-table :headers="complex.headers" :search="search" :no-data-text="$t('noDataText')"
                    :items="$store.state.planejamento.alinhadores" :rows-per-page-items="[
                      10,
                      25,
                      50,
                      { text: 'Todos', value: -1 },
                    ]" rows-per-page-text="Linhas por página" class="elevation-1" item-key="name"
                    v-model="complex.selected">
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.id }}</td>
                      <td class="text-center">
                        {{ props.item.quantidade_superior }}
                      </td>
                      <td class="text-center">
                        {{ props.item.quantidade_inferior }}
                      </td>
                      <td>
                        <v-tooltip top v-if="$store.state.planejamento.status !== 'Aprovado' && user.tipo === 1">
                          <template v-slot:activator="{ on }">
                            <v-btn color="success" depressed outline icon fab dark flat small v-on="on"
                              @click.native="aprovar(props.item)">
                              <v-icon>check</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("Aprovar") }}</span>
                        </v-tooltip>
                        <v-chip color="green" text-color="white" v-if="props.item.escolhido">{{ $t("Aprovado") }}
                        </v-chip>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
              <!-- Planos -->
              <v-layout v-if="
                $store.state.planejamento.origem !== 'Centro de Planejamento'
              ">
                <v-flex xs12 sm12 md12>
                  <v-toolbar flat color="white" class="px-0">
                    <v-toolbar-title>Planejamento - Alinhadores</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <planos v-if="$store.state.planejamento.status !== 'Aprovado'"
                      :planejamento="$store.state.planejamento"></planos>
                  </v-toolbar>
                  <v-data-table :headers="complexPlanos.headers" :search="search" :no-data-text="$t('noDataText')"
                    :items="$store.state.planejamento.alinhadores" :rows-per-page-items="[
                      10,
                      25,
                      50,
                      { text: 'Todos', value: -1 },
                    ]" rows-per-page-text="Linhas por página" class="elevation-1" item-key="name"
                    v-model="complexPlanos.selected">
                    <template slot="items" slot-scope="props">
                      <td>{{ props.index + 1 }}</td>
                      <td class="text-center">
                        {{ props.item }}
                      </td>
                      <td>
                        
                        <v-chip color="green" text-color="white"
                          v-if="$store.state.planejamento.plano_escolhido_id === (props.index + 1)">{{ $t("Aprovado") }}
                        </v-chip>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
              <!-- Histórico de Planejamentos -->
              <v-layout v-if="$store.state.complexPlanejamento.items.length > 0">
                <v-flex xs12 sm12 md12>
                  <v-toolbar flat color="white" class="px-0">
                    <v-toolbar-title>Histórico de Planejamento</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-data-table :headers="
                    user.tipo === 4
                      ? $store.state.complexPlanejamento.headersCredenciado
                      : $store.state.complexPlanejamento.headers
                  " :search="searchPlanejamento" :no-data-text="$t('noDataText')"
                    :items="$store.state.complexPlanejamento.items" :rows-per-page-items="[
                      10,
                      25,
                      50,
                      { text: 'Todos', value: -1 },
                    ]" rows-per-page-text="Linhas por página" class="elevation-1" item-key="name"
                    v-model="$store.state.complexPlanejamento.selected">
                    <template slot="items" slot-scope="props" @click.native="editar(props.item)">
                      <td>{{ props.item.id }}</td>
                      <td v-if="user.tipo !== 4">
                        {{ props.item.cliente.name }}
                      </td>
                      <td>{{ props.item.paciente }}</td>
                      <td>{{ props.item.status }}</td>
                      <td>{{ props.item.user.name }}</td>
                      <td>
                        {{ props.item.data_entrega | formatDate("D/MM/Y") }}
                      </td>
                      <td>
                        <v-btn color="primary" class="white--text" @click.native="abrirVisor(props.item.link)">
                          Link desta versão
                          <v-icon right dark>link</v-icon>
                        </v-btn>
                        <planejamento-view :planejamento="props.item" v-if="
                          user.tipo === 4 &&
                          props.item.status === 'Devolvido para Revisão'
                        "></planejamento-view>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
        <small>* campo obrigatório</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar">Sair</v-btn>
        <v-btn color="error" depressed outline dark flat @click.native="cancelar"
          v-if="user.tipo === 1 || user.tipo === 3">Excluir</v-btn>
        <v-btn color="blue darken-1" flat @click.native="salvar" v-if="isUndefined($store.state.planejamento.id)">Salvar
        </v-btn>
        <v-btn color="blue darken-1" flat @click.native="update" v-else>Atualizar</v-btn>

        <devolver :planejamento="planejamento" v-if="
          (user.tipo === 3 || user.tipo === 1) &&
          ($store.state.planejamento.status === 'Planejamento em Revisão' ||
            $store.state.planejamento.status === 'Devolvido para Revisão')
        " class="mr-3"></devolver>
        <devolver-arquivo :planejamento="planejamento" v-if="
          (user.tipo === 2 || user.tipo === 1 || user.tipo === 3) &&
          ($store.state.planejamento.status === 'Arquivos em análise')
        " class="mr-3"></devolver-arquivo>
        <v-btn color="success" flat @click.native="aprovacao" v-if="
          (user.tipo === 3 || user.tipo === 1) &&
          ($store.state.planejamento.status === 'Planejamento em Revisão' ||
            $store.state.planejamento.status === 'Devolvido para Revisão')
        ">Enviar para Aprovação</v-btn>
        <v-btn color="success" flat @click.native="producao" v-if="
          (user.tipo === 3 || user.tipo === 1 || user.tipo === 2) &&
          ($store.state.planejamento.status === 'Arquivos em análise' || $store.state.planejamento.status === 'Arquivos desaprovados')
        ">
          Enviar para Edição
        </v-btn>
        <v-btn color="orange" flat @click.native="revisao" v-if="
          user.tipo !== 4 &&
          ($store.state.planejamento.status === 'Planejamento em produção' ||
            $store.state.planejamento.status === 'Devolvido para Revisão' ||
            $store.state.planejamento.status ===
            'Devolvido para o planejamento' ||
            $store.state.planejamento.status === 'Planejamento em edição')
        ">Enviar para Revisão</v-btn>
        <v-btn color="success" flat @click.native="finalizar" v-if="
          user.tipo !== 4 && $store.state.planejamento.status === 'Aprovado'
        ">Finalizar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import Alinhador from "@/pages/artaligner/Casos/Alinhador.vue";
import Planos from "@/pages/artaligner/Casos/Planos.vue";
import Devolver from "@/pages/artaligner/Casos/Devolver.vue";
import DevolverArquivo from "@/pages/artaligner/Casos/DevolverArquivo.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");
import Arquivo from "@/pages/artaligner/Casos/Arquivo.vue";
import PlanejamentoView from "@/pages/artaligner/Casos/Planejamento.vue";
import { template } from "@babel/core";
export default {
  name: "Planejamento Modal",
  props: {
    novo: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      isEditing: false,
      model: null,
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      modalDataEntrada: false,
      modalDataValidade: false,
      // date:this.$store.state.planejamento.data_entrega,
      dateFormatted: this.formatDate(
        this.$store.state.planejamento.data_entrega
      ),
      menu1: false,
      dialog: false,
      valor: 16,
      slider: 256,
      tile: false,
      status: [
        "Aprovado",
        "Enviado",
        "Planejamento em Revisão",
        "Devolvido para Revisão",
        "Formulário recebido",
        "Planejamento em produção",
        "Enviado para aprovação",
        "Planejamento em edição",
        "Devolvido para o planejamento",
      ],
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Qnt de Alinhadores Superiores",
            value: "quantidade_superior",
            align: "center",
          },
          {
            text: "Qnt de Alinhadores Inferiores",
            value: "quantidade_inferior",
            align: "center",
          },

          {
            text: "Escolha",
            value: "",
          },
        ],
        items: [],
      },
      searchPlanejamento: "",
      complexPlanejamento: this.$store.state.complexPlanejamento,
      // mostrarAvatar: true,
    };
  },
  components: { Alinhador, Devolver, Planos, Arquivo, PlanejamentoView, DevolverArquivo },
  computed: {
    avatarSize() {
      return `${this.slider}px`;
    },
    user() {
      // console.log(this.$store.state.user)
      return this.$store.state.user;
    },
    computedDateFormatted() {
      return this.formatDate(this.$store.state.planejamento.data_entrega);
    },
    planejamento() {
      return this.$store.state.planejamento;
    },
    complexArquivos() {
      return this.$store.state.complexArquivos;
    },
    complexPlanos() {
      return this.$store.state.complexPlanos;
    },
    date() {
      return this.$store.state.planejamento.data_entrega;
    },
  },
  watch: {
    date(val) {
      // console.log(this.date)
      // console.log(val)
      // this.$store.state.planejamento.data_entrega = this.formatDate(this.date);
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    aprovar(item) {
      let alinhador = item;
      //Sinalizar como aprovado
      toastr.success("Aprovando alinhadores...", "AlignerPlan");
      this.$http
        .put(this.$store.state.urlBase + "alinhadores/aprovar/" + alinhador.id)
        .then((response) => {
          if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "AlignerPlan");
            }
          } else {
            toastr.success(this.$t(response.data.message), "AlignerPlan");
            //this.buscar();
            window.getApp.$emit("APP_PLANEJAMENTOS_UPDATED");
            window.getApp.$emit("APP_RECORD_UPDATED");
            this.atualizar();
          }
        });
    },
    cancelar() {
      var http = this.$http;
      var url = this.$store.state.urlBase;
      const id = this.$store.state.planejamento.id
      const origem = this.$store.state.planejamento.origem
      const atualizar = this.atualizar;
      toastr.warning(
        "<br /><button type='button' value='Sim' style='padding:2px 7px;border:1px solid;'>Sim</button><button type='button'  value='Não' style='padding:2px 7px;border:1px solid;margin-left:10px'>Não</button>",
        "Deseja realmente excluir este planejamento?",
        {
          allowHtml: true,
          onclick: function (toast) {
            var value = toast.target.value;

            if (value == "Sim") {
              alert("Iniciando a exclusão... aguarde!");
              alertExclusaoPlanejamento();
              http.defaults.headers.common["origem"] = origem
              http
                .delete(url + "planejamentos/" + id)
                .then((response) => {
                  toastr.success(response.data.message, "AlignerPlan");
                  window.getApp.$emit("APP_RECORD_UPDATED");
                  atualizar();
                });
            }
            toastr.remove();
            toastr.clear();
          },
        }
      );

    },
    finalizar() {
      this.$store.state.planejamento.finalizado = 1;
      this.update();
    },
    download(arquivo) {
      // alert(this.$store.state.planejamento.origem)
      if (this.$store.state.planejamento.origem === 'Centro de Planejamento') {
        /*toastr.success(this.$t("Baixando o arquivo...."), "AlignerPlan",{timeOut: 50000});
        this.$http
        .get(this.$store.state.urlBase + "arquivos/download/" + arquivo.id)
        .then((response) => {
          if (response.data.erro) {
            toastr.error(this.$t(response.data.erro), "AlignerPlan");
          } else {
            let file = response.data;
            this.forceDownload(file);
          }
        });*/
        window.open(this.$store.state.urlBase + "arquivos/download/" + arquivo.id, "_blank").focus();
      } else {
        window.open(arquivo.path, "_blank").focus();
      }



    },
    forceDownload(item) {
      toastr.success(this.$t("Gerando o arquivo...."), "AlignerPlan", { timeOut: 50000 });
      var pseudoelement = document.createElement("a");

      var filename = item.nome;
      var pseudoelement = document.createElement("a");
      // console.log(item)
      // console.log(item.file)
      let url = "data:" + item.tipo + ";base64," + item.file;
      // console.log(url)
      // var blob = new Blob([url], { type: item.tipo });

      // pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
      pseudoelement.setAttribute("href", url);
      pseudoelement.setAttribute("download", filename);

      pseudoelement.dataset.downloadurl = [
        item.tipo,
        pseudoelement.download,
        pseudoelement.href,
      ].join(":");
      pseudoelement.draggable = true;
      pseudoelement.classList.add("dragout");
      toastr.clear()
      pseudoelement.click();
    },
    producao() {
      if (this.$store.state.planejamento.planejador_id > 0) {
        this.$store.state.planejamento.status = "Planejamento em edição";
        this.update();
      } else {
        toastr.error(
          "Não é possível enviar planejamento para edição sem escolher o planejador!",
          "AlignerPlan"
        );
      }

    },
    aprovacao() {
      this.$store.state.planejamento.status = "Enviado para aprovação";
      this.update();
    },
    revisao() {
      this.$store.state.planejamento.status = "Planejamento em Revisão";
      this.update();
    },
    adicionarPlano() { },
    novoPlanejamento() {
      window.getApp.$emit("APP_CLIENTES_UPDATED");
      this.$store.state.planejamento = {};
    },
    isUndefined(value) {
      return typeof value === "undefined";
    },
    getPlanos() {

      this.$http.defaults.headers.common["origem"] = this.$store.state.planejamento.origem;
      this.$http
        .get(
          this.$store.state.urlBase +
          "planos/" +
          this.$store.state.planejamento.id
        )
        .then((response) => {
          this.$store.state.planejamento.alinhadores = response.data;
        });
    },
    salvar() {
      let planejamento = this.$store.state.planejamento;
      if (this.$store.state.planejamento.link) {
        toastr.success("Inserindo dados do Planejamento...", "AlignerPlan");
        this.$http
          .post(this.$store.state.urlBase + "planejamentos", planejamento)
          .then((response) => {
            window.getApp.$emit("APP_RECORD_UPDATED");
            toastr.success(
              "Planejamento Inserido com sucesso...",
              "AlignerPlan"
            );
            this.atualizar();
          });
        this.$store.state.planejamento = {};
        this.$store.commit("fecharModalPlanejamento");
      } else {
        toastr.error(
          "Não é possível inserir Planejamento sem link...",
          "AlignerPlan"
        );
      }
    },
    update() {
      toastr.success("Atualizando dados do Planejamento...", "AlignerPlan");
      // delete user.setor

      this.$store.state.planejamento.data_entrega = this.parseDate(
        this.dateFormatted
      );
      // console.log(this.$store.state.planejamento)
      this.$http
        .put(
          this.$store.state.urlBase +
          "planejamentos/" +
          this.$store.state.planejamento.id,
          this.$store.state.planejamento
        )
        .then((response) => {
          window.getApp.$emit("APP_RECORD_UPDATED");
          toastr.success(
            "Planejamento Atualizado com sucesso...",
            "AlignerPlan"
          );
          this.atualizar();
        });

      this.$store.state.cliente = {};
      this.$store.commit("fecharModalPlanejamento");
    },
    atualizar() {
      this.$store.state.planejamento = {};
      this.$store.state.complexArquivos.items = [];
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      // this.$store.state.dialogCliente = false


      this.$store.commit("fecharModalPlanejamento");
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toDateComplete(date) {
      let newDate = new Date(date);
      return newDate.toLocaleDateString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },

    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
  mounted() { },
  created() {
    delete window.getApp._events.APP_PLANOS_UPDATED;
    delete window.getApp._events.APP_FECHAR;
    window.getApp.$on("APP_PLANOS_UPDATED", this.getPlanos);
    window.getApp.$on("APP_FECHAR", this.atualizar);
    window.alertExclusaoPlanejamento = function () {
      toastr.success(
        "Aguarde a exclusão do Planejamento...",
        "AlignerPlan"
      );
    };
  },
};
</script>
