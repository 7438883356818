import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuetify from 'vuetify'

// import en from 'vuetify/lib/locale/en'
import pt from 'vuetify/lib/locale/pt'
// import br from 'vuetify/lib/locale/br'
import es from 'vuetify/lib/locale/es'

Vue.use(VueI18n)

const messages = {
  pt: {
    ...require('@/locales/pt.json'),
    $vuetify: pt,
  },
  es: {
    ...require('@/locales/es.json'),
    $vuetify: es,
  },

}

Vue.use(Vuetify, {
  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
})

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages,
})
