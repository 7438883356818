const Credenciado = [
  // { header: 'Apps' },
  {
    title: 'Dashboard',
    group: 'apps',
    icon: 'dashboard',
    name: 'Dashboard',
    isAdmin:false
  },
  {
    title: 'Submeta um caso',
    group: 'pages',
    icon: 'post_add',
    name: 'pages/submeta',
    isAdmin:false
  },
  {
    title: 'Seus Pacientes',
    group: 'pages',
    icon: 'folder_shared',
    name: 'pages/pacientes',
    isAdmin:false
  },
];
// reorder menu
Credenciado.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      let textA = x.title.toUpperCase();
      let textB = y.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }
});

export default Credenciado;
