<template>
  <v-dialog
    v-model="dialog"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        slot="activator"
        depressed
        outline
        icon
        fab
        dark
        :color="colorButton"
        small
        v-on="on"
        @click.native="buscar"
      >
        <v-icon>edit</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon flat dark @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title class="headline"
          >Planejamento
          <template>- {{ $t(planejamento.status) }}</template>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            flat
            dark
            @click.native="abrirFormulario"
            v-if="$store.getters.user.tipo === 4"
            >Abrir Formulário
          </v-btn>
          <v-btn
            flat
            dark
            @click.native="salvar"
            v-if="isUndefined(planejamento.id)"
            >Salvar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm12 md12>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-if="!isUndefined(planejamento.id)"
                    :disabled="!isUndefined(planejamento.id)"
                    label="ID *"
                    v-model="planejamento.id"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    label="Paciente"
                    v-model="planejamento.paciente"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="planejamento.link">
                  <!-- <v-text-field
                    label="Link"
                    v-model="planejamento.link"
                    disabled
                  ></v-text-field> -->
                  <v-btn
                    color="primary"
                    class="white--text"
                    block
                    @click.native="abrirVisor(planejamento.link)"
                  >
                    Link Visualizador
                    <v-icon right dark>link</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    name="input-7-1"
                    label="Parecer"
                    v-model="planejamento.parecer"
                    disabled
                  ></v-textarea>
                </v-flex>
                <template v-if="planejamento.relatorio">
                  <v-flex xs12 sm12 md12>
                    <v-input> Relatório </v-input>
                    <v-btn
                      color="primary"
                      class="white--text"
                      block
                      @click.native="downloadRelatorio(planejamento)"
                    >
                      {{ $t("Acessar Link") }}
                      <v-icon right dark>cloud_download</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      label="Descrição do Relatório"
                      disabled
                      v-model="planejamento.descricaoRelatorio"
                    ></v-text-field>
                  </v-flex>
                </template>
                <template v-if="planejamento.repositorio">
                  <v-flex xs12 sm12 md12>
                    <v-btn color="primary" class="white--text" block>
                      Repositório
                      <v-icon right dark>link</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      label="Descrição do Repositório"
                      disabled
                      v-model="planejamento.descricaoRepositorio"
                    ></v-text-field>
                  </v-flex>
                </template>
                <v-flex xs12 sm12 md12>
                  <v-select
                    :items="status"
                    label="Status"
                    v-model="planejamento.status"
                    disabled
                  ></v-select>
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  md3
                  v-if="
                    planejamento.id &&
                    planejamento.status === 'Devolvido para Revisão'
                  "
                >
                  <v-input
                    :messages="[
                      planejamento.data_entrega
                        ? toDateComplete(planejamento.data_reprovacao)
                        : '',
                    ]"
                    prepend-icon="schedule"
                  >
                    {{ $t("Data da Desaprovação") }}
                  </v-input>
                </v-flex>
                <v-flex xs12 sm12 md3 v-if="planejamento.id">
                  <v-input
                    :messages="[
                      planejamento.data_entrega
                        ? toDateComplete(planejamento.data_entrega)
                        : '',
                    ]"
                    prepend-icon="schedule"
                  >
                    {{ $t("Data da Entrega") }}
                  </v-input>
                </v-flex>
                <v-flex xs12 sm12 md3 v-if="planejamento.id">
                  <v-input
                    :messages="[toDateComplete(planejamento.updated_at)]"
                    prepend-icon="update"
                  >
                    {{ $t("Última Atualização") }}
                  </v-input>
                </v-flex>
                <v-flex xs12 sm12 md3 v-if="planejamento.id">
                  <v-input
                    :messages="[toDateComplete(planejamento.created_at)]"
                    prepend-icon="more_time"
                  >
                    {{ $t("Data de Criação") }}
                  </v-input>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm12 md12>
              <v-toolbar flat color="white" class="px-0">
                <v-toolbar-title>Arquivos STL Vinculados</v-toolbar-title>
                <v-spacer></v-spacer>
                <arquivo-add
                  class="text-right"
                  :planejamento="planejamento"
                  v-if="!planejamento.planejamento_id"
                ></arquivo-add>
              </v-toolbar>
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :no-data-text="$t('noDataText')"
                :items="complex.items"
                :rows-per-page-items="[
                  10,
                  25,
                  50,
                  { text: 'Todos', value: -1 },
                ]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.id }}</td>
                  <td>{{ props.item.nome }}</td>
                  <td>{{ props.item.tamanho }}</td>
                  <td>{{ props.item.tipo }}</td>
                  <td>{{ props.item.path }}</td>
                  <td>
                    {{ props.item.created_at | formatDate("DD/MM/Y H:m:s") }}
                  </td>
                  <td>
                    <v-btn
                      color="primary"
                      depressed
                      outline
                      icon
                      fab
                      dark
                      flat
                      small
                      @click="download(props.item)"
                    >
                      <v-icon>cloud_download</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm12 md12>
              <v-toolbar flat color="white" class="px-0">
                <v-toolbar-title>Planejamento - Alinhadores</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  dark
                  @click="adicionarPlano"
                  v-if="user.tipo !== 4"
                >
                  <v-icon left>library_add</v-icon>
                  Adicionar Quantidade de Alinhadores
                </v-btn>
              </v-toolbar>
              <v-data-table
                :headers="complexAlinhadores.headers"
                :search="searchAlinhadores"
                :no-data-text="$t('noDataText')"
                :items="planejamento.alinhadores"
                :rows-per-page-items="[
                  10,
                  25,
                  50,
                  { text: 'Todos', value: -1 },
                ]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complexAlinhadores.selected"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.id }}</td>
                  <td class="text-center">
                    {{ props.item.quantidade_superior }}
                  </td>
                  <td class="text-center">
                    {{ props.item.quantidade_inferior }}
                  </td>
                  <td>
                    <!-- <v-btn
                          color="primary"
                          depressed
                          outline
                          icon
                          fab
                          dark
                          flat
                          small
                          @click="download(props.item)"
                        >
                          <v-icon>check</v-icon>
                        </v-btn> -->
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
          <v-layout v-if="complexPlanejamento.items.length > 0">
            <v-flex xs12 sm12 md12>
              <v-toolbar flat color="white" class="px-0">
                <v-toolbar-title>Histórico de Planejamento</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-data-table
                :headers="
                  user.tipo === 4
                    ? complexPlanejamento.headersCredenciado
                    : complexPlanejamento.headers
                "
                :search="searchPlanejamento"
                :no-data-text="$t('noDataText')"
                :items="complexPlanejamento.items"
                :rows-per-page-items="[
                  10,
                  25,
                  50,
                  { text: 'Todos', value: -1 },
                ]"
                rows-per-page-text="Linhas por página"
                class="elevation-1"
                item-key="name"
                v-model="complexPlanejamento.selected"
              >
                <template
                  slot="items"
                  slot-scope="props"
                  @click.native="editar(props.item)"
                >
                  <td>{{ props.item.id }}</td>
                  <td v-if="user.tipo !== 4">{{ props.item.cliente.name }}</td>
                  <td>{{ props.item.paciente }}</td>
                  <td>{{ props.item.status }}</td>
                  <td>{{ props.item.user.name }}</td>
                  <td>{{ props.item.data_entrega | formatDate("D/MM/Y") }}</td>
                  <td>
                    <planejamento-view
                      :planejamento="props.item"
                      v-if="
                        user.tipo === 4 &&
                        props.item.status === 'Devolvido para Revisão'
                      "
                    ></planejamento-view>
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
        <small>* campo obrigatório</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="atualizar">Sair</v-btn>
        <v-btn
          color="blue darken-1"
          flat
          @click.native="salvar"
          v-if="isUndefined(planejamento.id)"
          >Salvar
        </v-btn>
        <!-- <v-btn color="blue darken-1" flat @click.native="update" v-else
          >Atualizar</v-btn
        > -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import toastr from "toastr";
import VWidget from "../../../components/VWidget.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
import ArquivoAdd from "@/pages/artaligner/Casos/Arquivo.vue";
import PlanejamentoView from "@/pages/artaligner/Casos/Planejamento.vue";
moment.locale("pt-BR");
export default {
  name: "PlanejamentoView",
  data() {
    return {
      modalDataEntrada: false,
      modalDataValidade: false,
      dialog: false,
      valor: 16,
      slider: 256,
      tile: false,
      status: [
        "Aprovado",
        "Enviado",
        "Planejamento em Revisão",
        "Devolvido para Revisão",
        "Formulário recebido",
        "Planejamento em produção",
        "Enviado para aprovação",
        "Planejamento em edição",
        "Devolvido para o planejamento",
      ],
      search: "",
      complex: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Nome",
            value: "nome",
          },
          {
            text: "Tamanho",
            value: "tamanho",
          },
          {
            text: "Tipo",
            value: "tipo",
          },
          {
            text: "Caminho",
            value: "path",
          },
          {
            text: "Criação",
            value: "created_at",
          },
          {
            text: "Download",
            value: "",
          },
        ],
        items: [],
      },
      searchPlanejamento: "",
      complexPlanejamento: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Cliente",
            value: "cliente.name",
          },
          {
            text: "Paciente",
            value: "paciente",
          },
          {
            text: "Status",
            value: "status",
          },
          {
            text: "Usuário",
            value: "user.name",
          },
          {
            text: "Entrega",
            value: "data_entrega",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        headersCredenciado: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Paciente",
            value: "paciente",
          },
          {
            text: "Status",
            value: "status",
          },
          {
            text: "Usuário",
            value: "user.name",
          },
          {
            text: "Entrega",
            value: "data_entrega",
          },
          {
            text: "Ações",
            value: "",
          },
        ],
        items: [],
      },
      searchAlinhadores: "",
      complexAlinhadores: {
        selected: [],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          {
            text: "Qnt de Alinhadores Superiores",
            value: "quantidade_superior",
            align: "center",
          },
          {
            text: "Qnt de Alinhadores Inferiores",
            value: "quantidade_inferior",
            align: "center",
          },

          {
            text: "Escolha",
            value: "",
          },
        ],
        items: [],
      },
      // mostrarAvatar: true,
    };
  },
  props: {
    planejamento: {},
    colorButton: {
      type: String,
      default: "primary"
    }
  },
  components: { VWidget, ArquivoAdd, PlanejamentoView },
  computed: {
    avatarSize() {
      return `${this.slider}px`;
    },
    user() {
      return this.$store.state.user;
    },
    urlForm() {
      let locale = this.$store.state.locale.locale;
      let url = [];
      url["pt"] =
        "https://docs.google.com/forms/d/e/1FAIpQLSdAEfByzk5fhsJNhonZVyR9Ei7nQ84XyJ5dgBGGYOxeZM99EQ/viewform";
      url["es"] =
        "https://docs.google.com/forms/d/e/1FAIpQLSeYhIMUcV0HqErkGPucPkrv3TLcI_TfgtNvWMDt4zeRYTDR-w/viewform";
      // console.log(locale);
      return url[locale];
    },
  },
  methods: {
    abrirFormulario() {
      window.open(this.urlForm, "_blank").focus();
    },
    abrirVisor(link) {
      console.log(link)
      window
        .open(link, "_blank")
        .focus();
    },
    adicionarPlano() {},
    buscar() {
      this.complex.items = [];
      toastr.success(
        this.$t("Buscando arquivos vinculados ao planejamento..."),
        "AlignerPlan"
      );
      // console.log(this.planejamento);
      this.$http
        .get(this.$store.state.urlBase + "arquivos/" + this.planejamento.id)
        //  .get(this.$store.state.urlBase + "user")
        .then((response) => {
          this.complex.items = response.data;
          // console.log(response.data);
        });
      this.getListHistorico();
    },
    getListHistorico() {
      this.complex.items = [];
      toastr.success(
        "Atualizando Lista de Histórico de Planejamentos...",
        "AlignerPlan"
      );
      this.$http
        .get(
          this.$store.state.urlBase +
            "planejamentos/historico/" +
            this.planejamento.id
        )
        //  .get(this.$store.state.urlBase + "user")
        .then((response) => {
          this.complexPlanejamento.items = response.data;
          // console.log(response.data);
        });
    },
    downloadRelatorio(planejamento) {
      window.open(planejamento.relatorio, "_blank").focus();
    },
    download(item) {
      this.$http
        .get(this.$store.state.urlBase + "arquivos/download/" + item.id)
        .then((response) => {
          if (response.data.erro) {
            toastr.error(this.$t(response.data.erro), "AlignerPlan");
          } else {
            let file = response.data;
            this.forceDownload(file);
          }
        });
    },
    forceDownload(item) {
      var pseudoelement = document.createElement("a");

      var filename = item.nome;
      var pseudoelement = document.createElement("a");
      // console.log(item)
      // console.log(item.file)
      let url = "data:" + item.tipo + ";base64," + item.file;
      // console.log(url)
      // var blob = new Blob([url], { type: item.tipo });

      // pseudoelement.setAttribute("href", window.URL.createObjectURL(blob));
      pseudoelement.setAttribute("href", url);
      pseudoelement.setAttribute("download", filename);

      pseudoelement.dataset.downloadurl = [
        item.tipo,
        pseudoelement.download,
        pseudoelement.href,
      ].join(":");
      pseudoelement.draggable = true;
      pseudoelement.classList.add("dragout");

      pseudoelement.click();
    },
    novoPlanejamento() {
      window.getApp.$emit("APP_CLIENTES_UPDATED");
      this.$store.state.planejamento = {};
    },
    isUndefined(value) {
      return typeof value === "undefined";
    },
    salvar() {
      let planejamento = this.$store.state.planejamento;
      if (this.$store.state.planejamento.link) {
        toastr.success("Inserindo dados do Planejamento...", "AlignerPlan");
        this.$http
          .post(this.$store.state.urlBase + "planejamentos", planejamento)
          .then((response) => {
            window.getApp.$emit("APP_RECORD_UPDATED");
            toastr.success(
              "Planejamento Inserido com sucesso...",
              "AlignerPlan"
            );
            this.atualizar();
          });
        this.$store.state.planejamento = {};
        this.$store.commit("fecharModalPlanejamento");
      } else {
        toastr.error(
          "Não é possível inserir Planejamento sem link...",
          "AlignerPlan"
        );
      }
    },
    update() {
      // toastr.success("Atualizando dados do Planejamento...", "AlignerPlan");
      // // delete user.setor
      // this.$http
      //   .put(
      //     this.$store.state.urlBase +
      //       "planejamentos/" +
      //       this.$store.state.planejamento.id,
      //     this.$store.state.planejamento
      //   )
      //   .then((response) => {
      //     window.getApp.$emit("APP_RECORD_UPDATED");
      //     toastr.success(
      //       "Planejamento Atualizado com sucesso...",
      //       "AlignerPlan"
      //     );
      //     this.atualizar();
      //   });
      // this.$store.state.cliente = {};
      // this.$store.commit("fecharModalPlanejamento");
    },
    atualizar() {
      this.$store.state.planejamento = {};
      this.dialog = false;
      // window.getApp.$emit('APP_FORNECEDOR_SUCATA_UPDATED')
      // this.$store.state.dialogCliente = false
      // this.$store.commit("fecharModalPlanejamento");
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toDateComplete(date) {
      let newDate = new Date(date);
      return newDate.toLocaleDateString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
  filters: {
    formatNumber(value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },

    formatDate(value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
  mounted() {},
  created() {
    window.getApp.$on("APP_ARQUIVO_UPDATED", this.buscar);
  },
};
</script>
<style scoped lang="css">
nav.v-toolbar >>> .v-toolbar__content {
  padding: 0;
}
</style>
