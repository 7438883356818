<template>
  <v-container grid-list-xl fluid>
    <v-layout row wrap>
      <v-flex lg3 sm6 xs12>
        <mini-statistic
          icon="verified"
          :title="String(indicadores.aprovado)"
          sub-title="Planejamentos Aprovados"
          color="green"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12>
        <mini-statistic
          icon="edit_note"
          :title="String(indicadores.producao)"
          sub-title="Planejamentos Em Produção ou Edição"
          color="blue lighten-2"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12>
        <mini-statistic
          icon="mdi-account-group"
          :title="String(indicadores.revisao)"
          sub-title="Planejamentos Em Revisão"
          color="orange"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12>
        <mini-statistic
          icon="mdi-account-group"
          :title="String(indicadores.aguardando)"
          sub-title="Planejamentos Aguardando..."
          color="red"
        ></mini-statistic>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="mt-3">
      <template v-for="(item, index) in devolvidoArquivo">
        <v-flex xs12 lg12 :key="item.id">
          <v-alert outline color="error" icon="warning" :value="true">
            <v-layout row pa-0>
              <v-flex v-ripple align-space-between xs10 pa-0 ma0 style="line-height: 50px;">
                <b>Planejamento:</b> {{ item.id }} - <b>Status:</b>
                {{ item.status }} - <b>Motivo:</b> {{ item.motivo }}
              </v-flex>
              <v-flex
                class="text-right"
                align-end
                justify-end
                xs2
                pa-0
                ma0
              >
                <!-- <v-btn
                  color="red darken-4"
                  dark
                  small
                  @click.native="editar(item)"
                >
                  <v-icon small>edit</v-icon>
                </v-btn> -->
                <!-- Planejamento -->
                    <planejamento-view
                      :planejamento="item"
                      colorButton="red darken-4"
                      v-if="
                        user.tipo === 4 &&
                        (item.status === 'Planejamento em produção' ||
                          item.status === 'Devolvido para Revisão' ||
                          item.status === 'Arquivos desaprovados' ||
                          item.status === 'Arquivos em análise' ||
                          item.status === 'Planejamento em edição')
                      "
                    ></planejamento-view>
              </v-flex>
            </v-layout>
          </v-alert>
        </v-flex>
      </template>
      <v-flex xs12 lg6>
        <v-card color="grey darken-3" class="white--text">
          <v-layout>
            <v-flex xs4 class="text-center red--text">
              <v-avatar color="transparent" size="150">
                <v-icon dark size="150">post_add</v-icon>
              </v-avatar>
            </v-flex>
            <v-flex xs8>
              <v-card-title primary-title>
                <div>
                  <div class="display-1">Submeta um caso</div>
                  <span class="body-1"
                    >Submeta um caso clínico para que nosso laboratório possa
                    confeccionar os Alinhadores Invisíveis.</span
                  >
                </div>
              </v-card-title>
            </v-flex>
          </v-layout>
          <v-divider light></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat dark to="submeta">Acessar</v-btn>
            <!-- <div class="pt-3 pr-3"><novo-caso inline-block></novo-caso></div> -->
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 lg6>
        <v-card color="grey darken-3" class="white--text">
          <v-layout>
            <v-flex xs4 class="text-center red--text">
              <v-avatar color="transparent" size="150">
                <v-icon dark size="150">folder_shared</v-icon>
              </v-avatar>
            </v-flex>
            <v-flex xs8>
              <v-card-title primary-title>
                <div>
                  <div class="display-1">Seus Pacientes</div>
                  <span class="body-1"
                    >Aqui você encontrará os relatórios e visualizadores de seus
                    pacientes, acompanhará o processo de envio de
                    planejamentos.</span
                  >
                </div>
              </v-card-title>
            </v-flex>
          </v-layout>
          <v-divider light></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat dark to="pacientes">Acessar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import MiniStatistic from "@/components/widgets/statistic/MiniStatistic";
import MenuCard from "@/components/widgets/card/MenuCard.vue";
import NameCard from "@/components/widgets/card/NameCard.vue";
import PostSingleCard from "@/components/widgets/card/PostSingleCard.vue";
import NovoCaso from "@/pages/comercial/NovoCaso.vue";
import toastr from "toastr";
import PlanejamentoModal from "@/pages/comercial/Planejamento.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
import PlanejamentoView from "@/pages/artaligner/Casos/Planejamento.vue";
import AprovacaoView from "@/pages/artaligner/Casos/Aprovacao.vue";
import AprovadoView from "@/pages/artaligner/Casos/Aprovado.vue";

export default {
  components: {
    MiniStatistic,
    MenuCard,
    NameCard,
    PostSingleCard,
    NovoCaso,
    PlanejamentoModal,
    PlanejamentoView,
    AprovacaoView,
    AprovadoView
  },
  data: () => ({
    cliente: {},
    indicadores: {
      producao: 0,
      aprovado: 0,
      revisao: 0,
      aguardando: 0,
    },
    // planejamentos: [],
  }),
  computed: {
    user() {
      // console.log(this.$store.state.user)
      return this.$store.state.user;
    },
    planejamentos() {
      return this.$store.state.planejamentos;
    },
    devolvidoArquivo() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.devolvidoArquivoMap);
      // console.log(this.emproducao);
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
  },
  methods: {
    isUndefined(value) {
      return typeof value === "undefined";
    },
    devolvidoArquivoMap(planejamento) {
      if (planejamento.status === "Arquivos desaprovados") {
        return {
          ...planejamento,
        };
      }
    },
    getIndicadores() {
      toastr.success(this.$t("Atualizando Indicadores..."), "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "indicadores")
        .then((response) => {
          this.indicadores = response.data;
          console.log(this);
        });
    },
    editar(item) {
      this.$store.commit("abrirModalPlanejamento", item);
    },
  },
  mounted() {
    // this.handleFullScreen();
    this.getIndicadores();
  },
  created() {
    delete window.getApp._events.APP_INDICADORES_UPDATED;
    window.getApp.$on("APP_INDICADORES_UPDATED", this.getIndicadores);
  },
};
</script>

