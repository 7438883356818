<template>
  <div class="text-xs-center text-right">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on"
          ><v-icon left>library_add</v-icon> Adicionar Plano de Alinhadores
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline primary white--text" primary-title>
          Adicionar Plano de Alinhadores
        </v-card-title>

        <v-card-text>
          <v-container grid-list-sm class="pa-4">
            <v-form ref="alinhador" v-model="valid" lazy-validation>
              <v-layout row wrap>
                <v-flex xs12 align-center justify-space-between>
                  <v-layout align-center>
                    <v-autocomplete
                      v-model="alinhador.plano"
                      :items="items"
                      color="white"
                      hide-no-data
                      hide-selected
                      item-text="text"
                      item-value="value"
                      label="Plano de Alinhadores"
                      placeholder="Escolha o plano de alinhadores"
                      prepend-icon="mdi-inventory"
                      return-object
                    ></v-autocomplete>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="salvar">{{
            $t("Inserir")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      dialog: false,
      alinhador: {},
      valid: false,
    };
  },
  methods: {
    salvar() {
      let alinhador = this.alinhador;
      let planejamento = this.planejamento;
      if (planejamento.id) {
        toastr.success(
          "Inserindo plano de alinhadores...",
          "AlignerPlan"
        );
        this.$http.defaults.headers.common["origem"] = planejamento.origem;
        this.$http
          .post(
            this.$store.state.urlBase +
              "planejamentos/alinhador/" +
              planejamento.id,
            alinhador
          )
          .then((response) => {
            window.getApp.$emit("APP_PLANOS_UPDATED");
            toastr.success(
              "Plano Inserido com sucesso...",
              "AlignerPlan"
            );
            this.$refs.alinhador.reset();
            this.dialog = false;
          });
      } else {
        toastr.error(
          "Não é possível inserir o plano para este planejamento...",
          "AlignerPlan"
        );
      }
    },
  },
  computed:{
    items(){
      let list = [];
      if(this.planejamento.origem === 'ArtAligner Brasil'){
        list[0] = {text:'PLANO SIX – 6 ALINHADORES',value:1}
        list[1] = {text:'PLANO LITE – 12 ALINHADORES',value:2}
        list[2] = {text:'PLANO STANDARD – 30 ALINHADORES',value:3}
        list[3] = {text:'PLANO ESSENTIAL – 50 ALINHADORES',value:4}
        list[4] = {text:'PLANO FULL – 80 ALINHADORES',value:5}
        list[5] = {text:'PLANO Lite + Six',value:6}
        list[6] = {text:'PLANO Essential + Lite',value:7}
        list[7] = {text:'PLANO Essential  + Six',value:8}
        list[8] = {text:'PLANO Essential + Lite',value:9}
        list[9] = {text:'PLANO SIM 10 pares',value:10}
        list[10] = {text:'PLANO SIM 20 pares',value:11}
      }else if(this.planejamento.origem === 'ArtAligner Espanha'){
        list[0] = {text:'Plan Lite – 10 Alineadores',value:1}
        list[1] = {text:'Plan Standard – 42 Alineadores',value:2}
        list[2] = {text:'Plan Full – 75 Alineadores',value:3}
      }
      return list
    }
  },
  props: {
    planejamento: {},
  },
};
</script>
