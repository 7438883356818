<template>
  <v-layout row justify-end>
    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">
          <v-icon left>library_add</v-icon>
          Adicionar Arquivo
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Enviar Arquivos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="submeter">Enviar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-5">
          <v-form ref="novoCaso">
            <v-layout>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  :label="$t('Arquivo STL')"
                  @click="onPickFile"
                  v-model="arquivo"
                  prepend-icon="attach_file"
                ></v-text-field>
                <!-- Hidden -->
                <input
                  type="file"
                  style="display: none"
                  ref="fileInput"
                  accept="*/*"
                  @change="onFilePicked"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import toastr from "toastr";
export default {
  name:"Arquivo",
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      fileObject: {},
      formData: {},
      arquivo: "",
      formatted: "",
      url: "",
      urlParsed: "",
      caso: {},
    };
  },
  props: {
    planejamento: {},
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;

      this.formData = new FormData();

      if (files[0] !== undefined) {
        let file = files[0];
        this.formData.append("file", file);
        this.arquivo = files[0].name;
        // Check validity of file
        if (this.arquivo.lastIndexOf(".") <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.url = fr.result;
          this.formatted = JSON.stringify(this.url, null, 2);
          this.fileObject = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.arquivo = "";
        this.fileObject = null;
        this.url = "";
      }
    },
    processarAnexos() {
      let arquivo = {};
      //arquivo = this.fileObject;
      //console.log(arquivo);
      arquivo.url = this.url;
      arquivo.name = this.fileObject.name;
      arquivo.size = this.fileObject.size;
      arquivo.type = this.fileObject.type;
      arquivo.lastModified = this.fileObject.lastModified;
      arquivo.lastModifiedDate = this.fileObject.lastModifiedDate;
      arquivo.webkitRelativePath = this.fileObject.webkitRelativePath;
      arquivo.formatted = this.formatted;

      //console.log(arquivo);
      this.caso.arquivo = arquivo;
    },
    limpar(){
      this.$refs.novoCaso.reset();
      this.fileObject= {}
      this.formData={} 
      this.arquivo="" 
      this.formatted="" 
      this.url="" 
      this.urlParsed="" 
      this.caso={} 
    },
    submeter() {
      this.processarAnexos();
      let planejamento = this.caso;
      planejamento.planejamento = this.planejamento;
      this.formData.append("planejamento", JSON.stringify(this.planejamento));
      //this.formData.append("arquivo", JSON.stringify(planejamento.arquivo));
      if (this.$refs.novoCaso.validate()) {
        let msgInicial = this.$t("Anexando arquivo ao Planejamento...");
        toastr.success(msgInicial, "AlignerPlan",{timeOut: 30000});
        this.$http
          // .post(this.$store.state.urlBase + "submissao/anexar", planejamento,{
          .post(this.$store.state.urlBase + "submissao/anexar", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          // .post(this.$store.state.urlBase + "submissao/anexar", formData)
          .then((response) => {
            if (response.data.message) {
              let msg = response.data.message;
              toastr.clear()
              toastr.success(this.$t(msg), "AlignerPlan");
              window.getApp.$emit("APP_ARQUIVO_UPDATED");
              this.limpar();
              this.dialog = false;
            } else if (response.data.errors) {
              toastr.clear()
              toastr.error(this.$t(response.data.errors), "AlignerPlan");
            }
          });
        this.caso = {};
        // this.$store.commit("fecharModalPlanejamento");
      } else {
        toastr.clear()
        toastr.error(
          this.$t("Preencha todos os campos obrigatórios antes de enviar!"),
          "AlignerPlan"
        );
      }
    },
  },
};
</script>
