<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialogDesaprovar" width="500">
      <v-tooltip top slot="activator">
        <template v-slot:activator="{ on }">
          <v-btn color="error" depressed outline dark flat v-on="on" left>
            <v-icon left>cancel</v-icon> {{ $t("Desaprovar arquivos") }}
          </v-btn>
        </template>
        <span>{{ $t("Desaprovar arquivos") }}</span>
      </v-tooltip>
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="red lighten-2" dark v-on="on"> Click Me </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{ $t("Desaprovar arquivos") }}
        </v-card-title>

        <v-card-text>
          <p class="my-2">
            <b>{{ $t('Informe os motivos para devolver este planejamento')}}</b>
          </p>
          <p>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-textarea
                v-model="motivo"
                :rules="[v => !!v || $t('Motivo é obrigatório')]"
                box
                name="motivo"
                label="Motivo da Devolução"
                value=""
              ></v-textarea>
            </v-form>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="desaprovar">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      dialogDesaprovar: false,
      motivo: "",
      valid:false
    };
  },
  props: {
    planejamento: {},
  },
  methods: {
    desaprovar() {
      let planejamento = this.planejamento;
      let dados = {};
      dados.motivo = this.motivo;
      dados.origem = planejamento.origem;
      //Sinalizar como aprovado
      let mensagem = this.$t("Devolvendo planejamento...");
      toastr.success(mensagem, "AlignerPlan");
      this.$http
        .put(
          this.$store.state.urlBase +
            "planejamentos/devolverArquivos/" +
            planejamento.id,
          dados
        )
        .then((response) => {
          if (typeof response.data.errors !== "undefined") {
            //Erros de Validação
            for (var error in response.data.errors) {
              toastr.error(response.data.errors[error], "AlignerPlan");
            }
          } else {
            toastr.success(this.$t(response.data.message), "AlignerPlan");
            window.getApp.$emit("APP_RECORD_UPDATED");
            window.getApp.$emit("APP_FECHAR");
            this.dialogDesaprovar = false;
          }
        });
    },
  },
};
</script>
