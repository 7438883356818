import Vue from 'vue';
import Vuetify from 'vuetify';


Vue.use(Vuetify,{
    theme: {
      primary: '#000000',
      secondary: '#b0bec5',
      accent: '#8c9eff',
      error: '#b71c1c'
    }
});

