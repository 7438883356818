<template>
  <!-- row justify-center -->
  <v-layout>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <!-- <v-btn color="primary" dark v-on="on">Open Dialog</v-btn> -->
        <v-btn flat dark v-on="on">Acessar</v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Novo Caso</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark flat @click="dialog = false">Save</v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <submeta></submeta>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import toastr from "toastr";
import Submeta from '@/pages/comercial/Submeta.vue';
export default {
  components: { Submeta },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {},
};
</script>
