<template>
  <v-container grid-list-xl fluid>
    <!-- Credenciados -->
    <template v-if="$store.getters.user.tipo === 4">
      <dash-credenciado></dash-credenciado>
    </template>
    <v-layout row wrap v-if="$store.getters.user.tipo !== 4">
      <!-- mini statistic start -->
      <v-flex
        lg3
        sm6
        xs12
        v-if="$store.getters.user.tipo === 2 || $store.getters.user.tipo === 3"
      >
        <mini-statistic
          icon="edit_note"
          :title="String(indicadores.producao)"
          sub-title="Planejamentos Em Produção"
          color="blue lighten-2"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 2">
        <mini-statistic
          icon="edit_note"
          :title="String(indicadores.edicao)"
          sub-title="Planejamento em edição"
          color="grey darken-2"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 3">
        <mini-statistic
          icon="edit_note"
          :title="String(indicadores.revisao)"
          sub-title="Planejamento em Revisão"
          color="orange"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 3">
        <mini-statistic
          icon="edit_note"
          :title="String(indicadores.devolvido)"
          sub-title="Devolvido para Revisão"
          color="red"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 2">
        <mini-statistic
          icon="edit_note"
          :title="String(indicadores.devolvido)"
          sub-title="Devolvido para o planejamento"
          color="orange"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 1">
        <mini-statistic
          icon="mdi-account-lock"
          :title="clientesPendentes.length + ''"
          sub-title="Clientes Pendentes"
          color="red"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 1">
        <mini-statistic
          icon="mdi-account-group"
          :title="indicadores.credenciados + ''"
          sub-title="Clientes Cadastrados"
          color="green"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12>
        <mini-statistic
          icon="assignment_ind"
          :title="usuarios.length + ''"
          sub-title="Usuários Cadastrados"
          color="light-blue"
        ></mini-statistic>
      </v-flex>
      <v-flex lg3 sm6 xs12 v-if="$store.getters.user.tipo === 1">
        <mini-statistic
          icon="fa fa-file-pdf-o"
          :title="planejamentos.length + ''"
          sub-title="Planejamentos"
          color="red"
        ></mini-statistic>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="$store.getters.user.tipo !== 4">
      <planejamento-modal
        v-if="user.tipo !== 4"
        :novo="false"
      ></planejamento-modal>
      <!-- mini statistic  end -->
      <v-flex
        :lg6="$store.getters.user.tipo !== 4 && $store.getters.user.tipo !== 2"
        :lg12="$store.getters.user.tipo === 2"
        sm12
        xs12
      >
        <template
          row
          wrap
          v-if="
            $store.getters.user.tipo !== 4 && $store.getters.user.tipo !== 2
          "
        >
          <v-widget
            title="Aprovado"
            content-bg="success"
            header-bg="success"
            dark
            class="mb-5"
          >
            <v-btn icon slot="widget-header-action">
              <v-icon class="text--secondary" @click.native="getPlanejamentos"
                >refresh</v-icon
              >
            </v-btn>
            <div slot="widget-content">
              <v-list>
                <template v-for="(item, index) in aprovados">
                  <v-list-tile
                    :key="item.id"
                    avatar
                    ripple
                    @click="editar(item)"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ item.cliente ? item.cliente.name : "" }} -
                        {{ item.origem }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title
                        >Paciente: {{ item.paciente }} | Planejador:
                        {{ item.planejador ? item.planejador.name : "" }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title
                        >Data de Entrega:
                        {{ toDate(item.data_entrega) }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn
                        flat
                        depressed
                        outline
                        icon
                        fab
                        color="primary"
                        small
                        @click.native="editar(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    v-if="index + 1 < devolvidoRevisao.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </div>
          </v-widget>
          <!-- Arquivos Desaprovados -->
          <v-widget
            title="Arquivos Desaprovados"
            content-bg="red darken-4"
            header-bg="red darken-4"
            dark
            class="mb-5"
          >
            <v-btn icon slot="widget-header-action">
              <v-icon class="text--secondary" @click.native="getPlanejamentos"
                >refresh</v-icon
              >
            </v-btn>
            <div slot="widget-content">
              <v-list>
                <template v-for="(item, index) in devolvidoArquivo">
                  <v-list-tile
                    :key="item.id"
                    avatar
                    ripple
                    @click="editar(item)"
                    :class="{
                    noDia: dif(item.data_entrega) === 0,
                    umDiaAntes:
                      dif(item.data_entrega) == 1 ||
                      dif(item.data_entrega) == 2,
                    atrasado: dif(item.data_entrega) < 0,
                  }"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ item.cliente ? item.cliente.name : "" }} -
                        {{ item.origem }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title
                        >Paciente: {{ item.paciente }} | Planejador:
                        {{ item.planejador ? item.planejador.name : "" }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title
                        >Data de Entrega:
                        {{ toDate(item.data_entrega) }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn
                        flat
                        depressed
                        outline
                        icon
                        fab
                        color="primary"
                        small
                        @click.native="editar(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    v-if="index + 1 < devolvidoRevisao.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </div>
          </v-widget>
          <v-widget
            title="Devolvido para Revisão"
            content-bg="red"
            header-bg="red"
            dark
            class="mb-5"
          >
            <v-btn icon slot="widget-header-action">
              <v-icon class="text--secondary" @click.native="getPlanejamentos"
                >refresh</v-icon
              >
            </v-btn>
            <div slot="widget-content">
              <v-list>
                <template v-for="(item, index) in devolvidoRevisao">
                  <v-list-tile
                    :key="item.id"
                    avatar
                    ripple
                    @click="editar(item)"
                    :class="{
                    noDia: dif(item.data_entrega) === 0,
                    umDiaAntes:
                      dif(item.data_entrega) == 1 ||
                      dif(item.data_entrega) == 2,
                    atrasado: dif(item.data_entrega) < 0,
                  }"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ item.cliente ? item.cliente.name : "" }} -
                        {{ item.origem }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title
                        >Paciente: {{ item.paciente }} | Planejador:
                        {{ item.planejador ? item.planejador.name : "" }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title
                        >Data de Entrega:
                        {{ toDate(item.data_entrega) }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn
                        flat
                        depressed
                        outline
                        icon
                        fab
                        color="primary"
                        small
                        @click.native="editar(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    v-if="index + 1 < devolvidoRevisao.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </div>
          </v-widget>
          <v-widget
            title="Em Revisão"
            content-bg="orange"
            header-bg="orange"
            dark
            class="mb-5"
          >
            <v-btn icon slot="widget-header-action">
              <v-icon class="text--secondary" @click.native="getPlanejamentos"
                >refresh</v-icon
              >
            </v-btn>
            <div slot="widget-content">
              <v-list>
                <template v-for="(item, index) in revisao">
                  <v-list-tile
                    :key="item.id"
                    avatar
                    ripple
                    @click="editar(item)"
                    :class="{
                    noDia: dif(item.data_entrega) === 0,
                    umDiaAntes:
                      dif(item.data_entrega) == 1 ||
                      dif(item.data_entrega) == 2,
                    atrasado: dif(item.data_entrega) < 0,
                  }"
                  >
                    <v-list-tile-content>
                      <v-list-tile-title
                        >{{ item.cliente ? item.cliente.name : "" }} -
                        {{ item.origem }}</v-list-tile-title
                      >
                      <v-list-tile-sub-title
                        >Paciente: {{ item.paciente }} | Planejador:
                        {{ item.planejador ? item.planejador.name : "" }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title
                        >Data de Entrega:
                        {{ toDate(item.data_entrega) }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn
                        flat
                        depressed
                        outline
                        icon
                        fab
                        color="primary"
                        small
                        @click.native="editar(item)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                  <v-divider
                    v-if="index + 1 < aprovacao.length"
                    :key="index"
                  ></v-divider>
                </template>
              </v-list>
            </div>
          </v-widget>
        </template>
        <v-widget
          title="Devolvido para Planejamento"
          content-bg="orange"
          header-bg="orange"
          dark
          class="mb-5"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getPlanejamentos"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <v-list>
              <template v-for="(item, index) in devolvido">
                <v-list-tile :key="item.id" avatar ripple @click="editar(item)" :class="{
                    noDia: dif(item.data_entrega) === 0,
                    umDiaAntes:
                      dif(item.data_entrega) == 1 ||
                      dif(item.data_entrega) == 2,
                    atrasado: dif(item.data_entrega) < 0,
                  }">
                  <v-list-tile-content>
                    <v-list-tile-title
                      >{{ item.cliente ? item.cliente.name : "" }} -
                      {{ item.origem }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      >Paciente: {{ item.paciente }} | Planejador:
                      {{ item.planejador ? item.planejador.name : "" }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title
                      >Data de Entrega:
                      {{ toDate(item.data_entrega) }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-btn
                      flat
                      depressed
                      outline
                      icon
                      fab
                      color="primary"
                      small
                      @click.native="editar(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < aprovacao.length"
                  :key="index + '_' + item.id"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-widget>
        <v-widget
          title="Em Edição"
          content-bg="grey"
          header-bg="grey"
          dark
          class="mb-5"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getPlanejamentos"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <v-list>
              <template v-for="(item, index) in aprovacao">
                <v-list-tile
                  :key="item.id + '_aprovavacao'"
                  avatar
                  ripple
                  @click="editar(item)"
                  dark
                  :class="{
                    noDia: dif(item.data_entrega) === 0,
                    umDiaAntes:
                      dif(item.data_entrega) == 1 ||
                      dif(item.data_entrega) == 2,
                    atrasado: dif(item.data_entrega) < 0,
                  }"
                >
                  <v-list-tile-content>
                    <v-list-tile-title
                      >{{ item.cliente ? item.cliente.name : "" }} -
                      {{ item.origem }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      >Paciente: {{ item.paciente }} | Planejador:
                      {{ item.planejador ? item.planejador.name : "" }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title
                      >Data de Entrega: {{ toDate(item.data_entrega) }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-btn
                      depressed
                      outline
                      icon
                      fab
                      dark
                      color="primary"
                      small
                      @click.native="editar(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < aprovacao.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-widget>
      </v-flex>
      <v-flex
        lg6
        sm12
        xs12
        v-if="$store.getters.user.tipo !== 4 && $store.getters.user.tipo !== 2"
      >
        <v-widget
          title="Arquivo em análise"
          content-bg="indigo"
          header-bg="indigo"
          dark
          class="mb-5"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getArquivosEmAnalise"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <v-list>
              <template v-for="(item, index) in emanalise">
                <v-list-tile :key="item.id" avatar ripple @click="editar(item)">
                  <v-list-tile-content>
                    <v-list-tile-title
                      >{{ item.cliente ? item.cliente.name : "" }} -
                      {{ item.origem }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      >Paciente: {{ item.paciente }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title
                      >ID: {{ item.id }} - Data de Criação:
                      {{ toDate(item.created_at) }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-btn
                      flat
                      depressed
                      outline
                      icon
                      fab
                      color="primary"
                      small
                      @click.native="editar(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < aprovacao.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-widget>
        <!-- <v-widget
          title="Em produção"
          content-bg="info"
          header-bg="info"
          dark
          class="mb-5"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getPlanejamentos"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <v-list>
              <template v-for="(item, index) in emproducao">
                <v-list-tile :key="item.id" avatar ripple @click="editar(item)">
                  <v-list-tile-content>
                    <v-list-tile-title
                      >{{ item.cliente ? item.cliente.name : "" }} -
                      {{ item.origem }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      >Paciente: {{ item.paciente }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title
                      >ID: {{ item.id }} - Data de Criação:
                      {{ toDate(item.created_at) }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-btn
                      flat
                      depressed
                      outline
                      icon
                      fab
                      color="primary"
                      small
                      @click.native="editar(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < aprovacao.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-widget> -->
        <v-widget
          title="Em aprovação"
          content-bg="teal darken-4"
          header-bg="teal darken-4"
          dark
          class="mb-5"
        >
          <v-btn icon slot="widget-header-action">
            <v-icon class="text--secondary" @click.native="getPlanejamentos"
              >refresh</v-icon
            >
          </v-btn>
          <div slot="widget-content">
            <v-list>
              <template v-for="(item, index) in emaprovacao">
                <v-list-tile :key="item.id" avatar ripple @click="editar(item)">
                  <v-list-tile-content>
                    <v-list-tile-title
                      >{{ item.cliente ? item.cliente.name : "" }} -
                      {{ item.origem }}</v-list-tile-title
                    >
                    <v-list-tile-sub-title
                      >Paciente: {{ item.paciente }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title
                      >ID: {{ item.id }} - Data de Entrega:
                        {{ toDate(item.data_entrega) }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-btn
                      flat
                      depressed
                      outline
                      icon
                      fab
                      color="primary"
                      small
                      @click.native="editar(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < aprovacao.length"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </div>
        </v-widget>
      </v-flex>
      
    </v-layout>
  </v-container>
</template>

<script>
import toastr from "toastr";
import API from "@/api";
import EChart from "@/components/chart/echart";
import MiniStatistic from "@/components/widgets/statistic/MiniStatistic";
import PostListCard from "@/components/widgets/card/PostListCard";
import ProfileCard from "@/components/widgets/card/ProfileCard";
import PostSingleCard from "@/components/widgets/card/PostSingleCard";
import WeatherCard from "@/components/widgets/card/WeatherCard";
import PlainTable from "@/components/widgets/list/PlainTable";
import PlainTableOrder from "@/components/widgets/list/PlainTableOrder";
import VWidget from "@/components/VWidget";
import Material from "vuetify/es5/util/colors";
import VCircle from "@/components/circle/VCircle";
import BoxChart from "@/components/widgets/chart/BoxChart";
import ChatWindow from "@/components/chat/ChatWindow";
import CircleStatistic from "@/components/widgets/statistic/CircleStatistic";
import LinearStatistic from "@/components/widgets/statistic/LinearStatistic";
import Util from "@/util";
import DashCredenciado from "@/pages/artaligner/Dashboard/Credenciado.vue";
import PlanejamentoModal from "@/pages/comercial/Planejamento.vue";
// import ResumoDiario from "@/pages/comercial/ResumoDiario.vue";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

var grafico;

export default {
  components: {
    API,
    VWidget,
    MiniStatistic,
    ChatWindow,
    VCircle,
    WeatherCard,
    PostSingleCard,
    PostListCard,
    ProfileCard,
    EChart,
    BoxChart,
    CircleStatistic,
    LinearStatistic,
    PlainTable,
    PlainTableOrder,
    DashCredenciado,
    PlanejamentoModal,
    // ResumoDiario,
  },
  data: () => ({
    renderQtdChart: false,
    renderQtdChartPeso: false,
    renderQtdChartQuantidade: false,
    renderQtdChartPesoSaidaEntrada: false,
    renderEstoque: false,
    color: Material,
    selectedTab: "tab-1",
    usuarios: [],
    planejadores: [],
    clientes: [],
    clientesEspanha: [],
    clientesPendentes: [],
    planejamentos: [],
    linearTrending: [
      {
        subheading: "Sales",
        headline: "2,55",
        caption: "increase",
        percent: 15,
        icon: {
          label: "trending_up",
          color: "success",
        },
        linear: {
          value: 15,
          color: "success",
        },
      },
      {
        subheading: "Revenue",
        headline: "6,553",
        caption: "increase",
        percent: 10,
        icon: {
          label: "trending_down",
          color: "error",
        },
        linear: {
          value: 15,
          color: "error",
        },
      },
      {
        subheading: "Orders",
        headline: "5,00",
        caption: "increase",
        percent: 50,
        icon: {
          label: "arrow_upward",
          color: "info",
        },
        linear: {
          value: 50,
          color: "info",
        },
      },
    ],
    trending: [
      {
        subheading: "Email",
        headline: "15+",
        caption: "email opens",
        percent: 15,
        icon: {
          label: "email",
          color: "info",
        },
        linear: {
          value: 15,
          color: "info",
        },
      },
      {
        subheading: "Tasks",
        headline: "90%",
        caption: "tasks completed.",
        percent: 90,
        icon: {
          label: "list",
          color: "primary",
        },
        linear: {
          value: 90,
          color: "success",
        },
      },
      {
        subheading: "Issues",
        headline: "100%",
        caption: "issues fixed.",
        percent: 100,
        icon: {
          label: "bug_report",
          color: "primary",
        },
        linear: {
          value: 100,
          color: "error",
        },
      },
    ],
    indicadores: {
      producao: 0,
      edicao: 0,
      devolvido: 0,
      aguardando: 0,
      credenciados: 0,
    },
    sinalizacao: [],
  }),
  computed: {
    user() {
      // console.log(this.$store.state.user)
      return this.$store.state.user;
    },
    aprovados() {
      let aprovacao = [];
      let aprovacoes = [];
      aprovacao = this.planejamentos.map(this.aprovadosMap);
      // console.log(this.planejamentos)
      aprovacao.forEach((value) => {
        if (!this.isUndefined(value)) aprovacoes.push(value);
      });
      return aprovacoes;
    },
    aprovacao() {
      let aprovacao = [];
      let aprovacoes = [];
      aprovacao = this.planejamentos.map(this.aprovacaoMap);
      // console.log(this.planejamentos)
      aprovacao.forEach((value) => {
        if (!this.isUndefined(value)) aprovacoes.push(value);
      });
      return aprovacoes;
    },
    emanalise() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.emanaliseMap);
      // console.log(this.planejamentos)
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    emproducao() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.emproducaoMap);
      // console.log(this.planejamentos)
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    emaprovacao() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.emaprovacaoMap);
      // console.log(this.planejamentos)
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    devolvidoRevisao() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.devolvidoRevisaoMap);
      // console.log(this.emproducao);
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    devolvidoArquivo() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.devolvidoArquivoMap);
      // console.log(this.emproducao);
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    revisao() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.revisaoMap);
      // console.log(this.planejamentos)
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    devolvido() {
      let emproducao = [];
      let todosemproducao = [];
      emproducao = this.planejamentos.map(this.devolvidoMap);
      // console.log(this.planejamentos)
      emproducao.forEach((value) => {
        if (!this.isUndefined(value)) todosemproducao.push(value);
      });
      return todosemproducao;
    },
    options: function () {
      return [
        ["dataset.source", this.siteTrafficData],
        ["color", [this.color.lightBlue.base, this.color.green.lighten1]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "bar"],
        ["series[0].areaStyle", {}],
        ["series[0].smooth", true],
        ["series[1].smooth", true],
        ["series[1].type", "bar"],
        ["series[1].areaStyle", {}],
      ];
    },
    optionsQuantidade: function () {
      return [
        ["dataset.source", this.siteTrafficDataQuantidade],
        ["color", [this.color.green.lighten1]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "line"],
        ["series[0].smooth", false],
      ];
    },
    optionsPeso: function () {
      return [
        ["dataset.source", this.siteTrafficDataPeso],
        ["color", [this.color.lightBlue.base, this.color.green.lighten1]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "bar"],
        ["series[0].areaStyle", {}],
        ["series[0].smooth", true],
        ["series[1].smooth", true],
        ["series[1].type", "bar"],
        ["series[1].areaStyle", {}],
      ];
    },
    optionsPesoSaidaEntrada: function () {
      return [
        ["dataset.source", this.siteTrafficDataPesoSaidaEntrada],
        ["color", [this.color.lightBlue.base, this.color.amber.base]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "bar"],
        ["series[0].areaStyle", {}],
        ["series[0].smooth", true],
        ["series[1].smooth", true],
        ["series[1].type", "bar"],
        ["series[1].areaStyle", {}],
      ];
    },
    optionsEstoque: function () {
      return [
        ["dataset.source", this.siteTrafficDataEstoque],
        ["color", [this.color.lightBlue.base, this.color.red.base]],
        ["legend.show", true],
        ["xAxis.axisLabel.show", true],
        ["yAxis.axisLabel.show", true],
        ["grid.left", "2%"],
        ["grid.bottom", "5%"],
        ["grid.right", "3%"],
        ["series[0].type", "line"],
        // ['series[0].areaStyle', {}],
        ["series[0].smooth", true],
        // ['series[1].smooth', true],
        // ['series[1].type', 'line'],
        // ['series[1].areaStyle', {}],
      ];
    },
    activity() {
      return API.getActivity();
    },
    posts() {
      return API.getPost(3);
    },
    siteTrafficData() {
      return this.$store.getters.saidasCliente;
    },
    siteTrafficDataQuantidade() {
      return this.$store.getters.saidasClienteQuantidade;
    },
    siteTrafficDataPeso() {
      // return API.monthVisitData
      return this.$store.state.pesoSaidasCliente;
    },
    siteTrafficDataPesoSaidaEntrada() {
      // return API.monthVisitData
      return this.$store.state.pesoSaidaEntrada;
    },
    siteTrafficDataEstoque() {
      // return API.monthVisitData
      return this.$store.state.estoque;
    },
    dataset: function () {
      var dataSet = {
        source: this.$store.getters.saidasCliente,
      };
      // console.log(dataSet)
      return dataSet;
    },
    locationData() {
      return API.getLocation;
    },
  },
  filters: {
    toDateComplete(date) {
      console.log(date);
      let newDate = new Date(date);
      return newDate.toLocaleDateString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
  methods: {
    getPlanejador(item){
      if(item.planejador){
        return planejador.name;
      }
    },
    getListHistorico(id) {
      toastr.success(
        "Atualizando Lista de Histórico de Planejamentos...",
        "AlignerPlan"
      );
      this.$http
        .get(this.$store.state.urlBase + "planejamentos/historico/" + id)
        //  .get(this.$store.state.urlBase + "user")
        .then((response) => {
          this.$store.state.complexPlanejamento.items = response.data;
          // console.log(response.data);
        });
    },
    getArquivos(item) {
      this.$http.defaults.headers.common["origem"] = item.origem;
      this.$http
        .get(this.$store.state.urlBase + "arquivos/" + item.id)
        //  .get(this.$store.state.urlBase + "user")
        .then((response) => {
          this.$store.state.complexArquivos.items = response.data;
          // console.log(response.data);
        });
    },
    editar(item) {
      //window.getApp.$emit("APP_CLIENTES_UPDATED");
      //window.getApp.$emit("APP_USER_UPDATED");
      // console.log(item)
      this.getPlanejadores();
      this.getListHistorico(item.id);
      this.getArquivos(item);
      this.$store.commit("abrirModalPlanejamento", item);
    },
    isUndefined(value) {
      return typeof value === "undefined";
    },
    devolvidoRevisaoMap(planejamento) {
      if (planejamento.status === "Devolvido para Revisão") {
        return {
          ...planejamento,
        };
      }
    },
    devolvidoArquivoMap(planejamento) {
      if (planejamento.status === "Arquivos desaprovados") {
        return {
          ...planejamento,
        };
      }
    },
    aprovacaoMap(planejamento) {
      if (planejamento.status === "Planejamento em edição") {
        return {
          ...planejamento,
        };
      }
    },
    aprovadosMap(planejamento) {
      if (planejamento.status === "Aprovado" && planejamento.finalizado === 0) {
        return {
          ...planejamento,
        };
      }
    },
    emanaliseMap(planejamento) {
      if (planejamento.status === "Arquivos em análise") {
        return {
          ...planejamento,
        };
      }
    },
    emproducaoMap(planejamento) {
      if (planejamento.status === "Planejamento em produção") {
        return {
          ...planejamento,
        };
      }
    },
    emaprovacaoMap(planejamento) {
      if (planejamento.status === "Enviado para aprovação") {
        return {
          ...planejamento,
        };
      }
    },
    revisaoMap(planejamento) {
      if (planejamento.status === "Planejamento em Revisão") {
        return {
          ...planejamento,
        };
      }
    },
    devolvidoMap(planejamento) {
      if (planejamento.status === "Devolvido para o planejamento") {
        return {
          ...planejamento,
        };
      }
    },
    abrirVisor(item) {
      window
        .open(this.$store.state.urlBaseWeb + "visor/" + item.id, "_blank")
        .focus();
    },
    toDateComplete(date) {
      let newDate = new Date(date);
      return newDate.toLocaleDateString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    toDate(value, fmt = "DD/MM/YYYY") {
      // let data = new Date(date);
      // let dataFormatada = ((data.getDate() )) + "/" + ((data.getMonth() + 1)) + "/" + data.getFullYear();
      // return dataFormatada;
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
    dif(data) {
      const date1 = new Date(data);
      const date2 = new Date();
      // const diffTime = Math.abs(date2 - date1);
      const diffTime = date1 - date2;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // const diffDays = diffTime / (1000 * 60 * 60 * 24);
      // console.log(diffTime + " milliseconds");
      // console.log(diffDays + " days");
      return diffDays;
    },
    getAcessoRotaNegado(permissoes, path) {
      let acessoNegado = true;
      acessoNegado = API.getAcessoRotaNegado(permissoes, path, false);
      // console.log(acessoNegado)
      return acessoNegado;
    },
    getPlanejadores() {
      let msgInicial = this.$t("Buscando lista de planejadores...");
      toastr.success(msgInicial, "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "planejadores")
        .then((response) => {
          this.planejadores = response.data;
          this.$store.state.planejadores = this.planejadores;
          // console.log(this.usuarios);
        });
    },
    getUsuarios() {
      let msgInicial = this.$t("Buscando lista de usuários...");
      toastr.success(msgInicial, "AlignerPlan");
      this.$http.get(this.$store.state.urlBase + "users").then((response) => {
        this.usuarios = response.data;
        this.$store.state.usuarios = this.usuarios;
        // console.log(this.usuarios);
      });
    },
    getClientes() {
      let msgInicial = this.$t("Buscando lista de clientes...");
      toastr.success(msgInicial, "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "clientes")
        .then((response) => {
          this.clientes = response.data;
          this.$store.state.clientes = this.clientes;
          // console.log(this.clientes);
        });
    },
    getClientesEspanha() {
      let msgInicial = this.$t("Buscando lista de clientes...");
      toastr.success(msgInicial, "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "clientes/Espanha")
        .then((response) => {
          this.clientesEspanha = response.data;
          this.$store.state.clientesEspanha = this.clientesEspanha;
          // console.log(this.clientes);
        });
    },
    getClientesPendentes() {
      let msgInicial = this.$t("Buscando lista de clientes...");
      toastr.success(msgInicial, "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "clientes/status/0")
        .then((response) => {
          this.clientesPendentes = response.data;
          this.$store.state.clientesPendentes = this.clientesPendentes;
          // console.log(this.clientes);
        });
    },
    getPlanejamentos() {
      let msgInicial = this.$t("Buscando lista de planejamentos...");
      toastr.success(msgInicial, "AlignerPlan",{timeOut: 50000});
      this.$http
        .get(this.$store.state.urlBase + "planejamentos")
        .then((response) => {
          this.planejamentos = response.data;
          this.$store.state.planejamentos = this.planejamentos;
          toastr.clear()
          // console.log(this.clientes);
        });
    },
    relatorioDiretoria() {
      let msgInicial = this.$t("Gerando Resumo do Dia...");
      toastr.success(msgInicial, "AlignerPlan");
      window.getApp.$emit("APP_RESUMO_DIRETORIA");
      this.$store.commit("abrirModalResumoDiaro");
    },
    getSaidasCliente() {
      toastr.success(
        this.$t("Atualizando Gráfico de Quantidade de Saídas por Cliente..."),
        "AlignerPlan"
      );
      this.renderQtdChart = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/SaidasCliente/15")
        .then((response) => {
          this.$store.state.saidasCliente = response.data;
          this.renderQtdChart = true;
        });
    },
    getPesoSaidasCliente() {
      toastr.success(
        this.$t("Atualizando Gráfico de Peso de Saídas por Cliente..."),
        "AlignerPlan"
      );
      this.renderQtdChartPeso = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/PesoSaidasCliente/15")
        .then((response) => {
          this.$store.state.pesoSaidasCliente = response.data;
          this.renderQtdChartPeso = true;
        });
    },
    getPesoSaidaEntrada() {
      toastr.success(
        this.$t("Atualizando Gráfico de Peso de Saídas x Entrada..."),
        "AlignerPlan"
      );
      this.renderQtdChartPesoSaidaEntrada = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/PesoSaidaEntrada/15")
        .then((response) => {
          this.$store.state.pesoSaidaEntrada = response.data;
          this.renderQtdChartPesoSaidaEntrada = true;
        });
    },
    getEstoque() {
      toastr.success("Atualizando Variação do Estoque...", "AlignerPlan");
      this.renderEstoque = false;
      this.$http
        .get(this.$store.state.urlBase + "v1/comercial/Estoque/30")
        .then((response) => {
          this.$store.state.estoque = response.data;
          this.renderEstoque = true;
        });
    },
    getSaidasClienteQuantidade() {
      toastr.success(
        "Atualizando Gráfico de Saídas Quantidade de Carretas...",
        "AlignerPlan"
      );
      this.renderQtdChartQuantidade = false;
      this.$http
        .get(
          this.$store.state.urlBase + "v1/comercial/SaidasClienteQuantidade/15"
        )
        .then((response) => {
          this.$store.state.saidasClienteQuantidade = response.data;
          this.renderQtdChartQuantidade = true;
        });
    },
    getIndicadores() {
      toastr.success(this.$t("Atualizando Indicadores..."), "AlignerPlan");
      this.$http
        .get(this.$store.state.urlBase + "indicadores")
        .then((response) => {
          this.indicadores = response.data;
          // console.log(this);
        });
    },
    getList() {
      toastr.success("Atualizando dashboard...", "AlignerPlan");
      window.getApp.$emit("APP_INDICADORES_UPDATED");
      // window.getApp.$emit("APP_ESTOQUE_FISICO_GET");
      // window.getApp.$emit("APP_ESTOQUE_CONTABIL_GET");
      // this.getSaidasCliente();
      // this.getPesoSaidasCliente();
      // this.getSaidasClienteQuantidade();
      // this.getPesoSaidaEntrada();
      this.getIndicadores();
      this.getUsuarios();
      this.getClientes();
      this.getClientesPendentes();
      this.getPlanejamentos();
    },
    handleDrawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    logout: function () {
      window.localStorage.removeItem("user");
      this.$router.push("/login");
      this.$session.destroy();
      delete this.user;
    },
  },
  mounted() {
    // this.handleFullScreen();
    this.getList();
  },
  created() {
    //console.log(this.$refs.chart);
    //  var user = window.localStorage.getItem('user')
    //  console.log(user)
    delete window.getApp._events.APP_RECORD_UPDATED;
    delete window.getApp._events.APP_CLIENTES_UPDATED;
    delete window.getApp._events.APP_CLIENTES_PENDENTES_UPDATED;
    delete window.getApp._events.APP_USER_UPDATED;
    window.getApp.$on("APP_RECORD_UPDATED", this.getList);
    window.getApp.$on("APP_CLIENTES_UPDATED", this.getClientes);
    window.getApp.$on("APP_CLIENTES_ESPANHA_UPDATED", this.getClientesEspanha);
    window.getApp.$on(
      "APP_CLIENTES_PENDENTES_UPDATED",
      this.getClientesPendentes
    );
    window.getApp.$on("APP_USER_UPDATED", this.getUsuarios);
    //Validando Login
    if (!this.$session.exists()) {
      this.logout();
    } else {
      this.user = API.getUserStorage();
      this.$store.state.user = this.user;
      //this.user = this.$store.state.user
      // console.log(this.user)
      this.$store.commit("setUser", this.user);
      this.$http.defaults.headers.common["Authorization"] =
        "Bearer " + this.user.token;
      //this.$http.defaults.headers.common["CdLoja"] = this.$store.state.CdLoja;
    }
  },
};
</script>
<style>
.ponteiro {
  cursor: pointer;
}
.atrasado {
  background-color: rgba(244, 67, 54, 0.9);
}
.noDia,
.theme--light.v-list .noDia .v-list__tile__sub-title,
.atrasado,
.theme--light.v-list .atrasado .v-list__tile__sub-title {
  color: white;
}
.noDia {
  background-color: rgba(0, 0, 0, 0.8);
}
.umDiaAntes {
  background-color: rgba(180, 180, 180, 0.5);
}
.v-list .noDia .v-list__tile--link:hover,
.v-list .atrasado .v-list__tile--link:hover,
.theme--light.v-list .noDia .v-list__tile--link:hover,
.theme--light.v-list .noDia .v-list__tile--highlighted,
.theme--light.v-list
  .noDia
  .v-list__group__header:hover
  .theme--light.v-list
  .atrasado
  .v-list__tile--link:hover,
.theme--light.v-list .atrasado .v-list__tile--highlighted,
.theme--light.v-list .atrasado .v-list__group__header:hover {
  color: rgba(0, 0, 0, 0.8);
}
.theme--light.v-list .noDia .v-list__tile--link:hover .v-list__tile__sub-title,
.theme--light.v-list .noDia .v-list__tile--link:hover .v-list__tile--link,
.theme--light.v-list .atrasado .v-list__tile--link:hover .v-list__tile--link,
.theme--light.v-list
  .atrasado
  .v-list__tile--link:hover
  .v-list__tile__sub-title {
  color: rgba(0, 0, 0, 0.54) !important;
}
/* .v-chart canvas,.v-chart > div {
    /* max-width: 100% !important; */
/* height: 300px; */
/* } */
/* .echarts {
        height: 300px;
    } */
</style>
