<template>
  <v-stepper v-model="passo" class="mt-3">
    <v-stepper-header>
      <v-stepper-step :complete="passo > 1" step="1">{{
        $t("Dados do Paciente")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="passo > 2" step="2">{{
        $t("Envio de Formulário")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="passo > 3" step="3">{{
        $t("Envio de Arquivos STL ou Modelos")
      }}</v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-form ref="novoCaso" v-model="valid" lazy-validation>
          <v-layout>
            <v-flex xs12 md12>
              <v-text-field
                v-model="caso.paciente"
                :label="$t('Nome do Paciente')"
                :rules="[
                  (v) => !!v || $t('Nome do Paciente é um campo obrigatório'),
                ]"
                required
                outline
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-btn color="primary" @click="passo = 2">
          {{ $t("Passo 2: Envio de Formulário") }}
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <iframe
          :src="urlForm"
          width="100%"
          height="850"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          scrolling="auto"
          style="display: inline-block; overflow: hidden; line-height: 0"
          class="mce_SELRES_start"
          >Carregando&#8230;</iframe
        >
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-btn color="primary" @click="passo = 3">
          {{ $t("Passo 3: Envio de Arquivos STL ou Modelos") }}
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="3">
        <p>
          Caso não tenha os modelos ou os arquivo agora, não se preocupe. Depois
          de submeter você poderá acessar os seus casos e enviar os arquivos do
          respectivo caso. {{ caso.arquivo }}
        </p>
        <v-form>
          <v-layout>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :label="$t('Arquivo STL Superior')"
                @click="onPickFile"
                v-model="arquivo"
                prepend-icon="attach_file"
              ></v-text-field>
              <!-- Hidden -->
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="*/*"
                @change="onFilePicked"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 sm12 md12>
              <v-text-field
                :label="$t('Arquivo STL Inferior')"
                @click="onPickFile2"
                v-model="arquivo2"
                prepend-icon="attach_file"
              ></v-text-field>
              <!-- Hidden -->
              <input
                type="file"
                style="display: none"
                ref="fileInput2"
                accept="*/*"
                @change="onFilePicked2"
              />
            </v-flex>
          </v-layout>
        </v-form>
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <v-btn color="primary" @click.native="submeter"> Submeter </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import toastr from "toastr";
export default {
  data() {
    return {
      caso: {},
      passo: 0,
      valid: false,
      fileObject: {},
      fileObject2: {},
      formData:{},
      arquivo: "",
      arquivo2: "",
      url: "",
      url2: "",
    };
  },
  computed: {
    urlForm() {
      let locale = this.$store.state.locale.locale;
      let url = [];
      url["pt"] =
        "https://docs.google.com/forms/d/e/1FAIpQLSdAEfByzk5fhsJNhonZVyR9Ei7nQ84XyJ5dgBGGYOxeZM99EQ/viewform";
      url["es"] =
        "https://docs.google.com/forms/d/e/1FAIpQLSeYhIMUcV0HqErkGPucPkrv3TLcI_TfgtNvWMDt4zeRYTDR-w/viewform";
      // console.log(locale);
      return url[locale];
    },
  },
  created(){
    this.formData = new FormData();
  },
  methods: {
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;

      
      if (files[0] !== undefined) {
        let file = files[0];
        this.formData.append("file", file);
        this.arquivo = files[0].name;
        // Check validity of file
        if (this.arquivo.lastIndexOf(".") <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.url = fr.result;
          this.fileObject = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.arquivo = "";
        this.fileObject = null;
        this.url = "";
      }
    },
    onPickFile2() {
      this.$refs.fileInput2.click();
    },
    onFilePicked2(event) {
      const files = event.target.files;

      if (files[0] !== undefined) {
        let file = files[0];
        this.formData.append("file2", file);
        this.arquivo2 = files[0].name;
        // Check validity of file
        if (this.arquivo2.lastIndexOf(".") <= 0) {
          return;
        }
        // If valid, continue
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.url2 = fr.result;
          this.fileObject2 = files[0]; // this is an file that can be sent to server...
        });
      } else {
        this.arquivo2 = "";
        this.fileObject2 = null;
        this.url2 = "";
      }
    },
    processarAnexos() {
      let arquivo = {};
      // arquivo = this.fileObject;
      arquivo.url = this.url;
      arquivo.name = this.fileObject.name;
      arquivo.size = this.fileObject.size;
      arquivo.type = this.fileObject.type;

      this.caso.arquivo = arquivo;
    },
    submeter() {
      this.processarAnexos();
      let planejamento = this.caso;
      //console.log(planejamento);
      this.formData.append("paciente", JSON.stringify(planejamento.paciente));
      // if (this.$store.state.planejamento.link) {
      if (this.$refs.novoCaso.validate()) {
        let msgInicial = this.$t("Inserindo dados do Planejamento...");
        toastr.success(msgInicial, "AlignerPlan",{timeOut: 360000});
        this.$http
          .post(this.$store.state.urlBase + "submissao", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // window.getApp.$emit("APP_RECORD_UPDATED");
            if (response.data.message) {
              let msg = response.data.message;
              toastr.clear()
              toastr.success(
                // this.$t("Planejamento Inserido com sucesso..."),
                this.$t(msg),
                "AlignerPlan"
              );
              this.$router.push({ path: "/pacientes" });
            }else if (response.data.errors) {
              toastr.clear()
              toastr.error(this.$t(response.data.errors), "AlignerPlan");
            }
          });
        this.caso = {};
        // this.$store.commit("fecharModalPlanejamento");
      } else {
        toastr.clear()
        toastr.error(
          this.$t("Preencha todos os campos obrigatórios antes de enviar!"),
          "AlignerPlan"
        );
        this.passo = 1;
      }
    },
  },
};
</script>
