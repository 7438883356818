<template></template>
<script>
import API from "@/api/";
export default {
  data: () => ({
    expanded: true,
    rightDrawer: false,
    snackbar: {
      show: false,
      text: "",
      color: "",
    },
  }),

  computed: {},
  created() {
    const token = atob(this.$route.params.hash);
    const route = atob(this.$route.params.route);
    // console.log(token);
    // console.log(route);
    let user = {};
    this.$http.defaults.headers.common["Authorization"] = "Bearer " + token;
    this.$http.get(this.$store.state.urlBase + "user").then((response) => {
      user = response.data;
      this.$session.start();
      this.$session.set("user", response.data);
      this.$store.state.user = response.data;
      // this.$store.commit("setUser", response.data);
      // window.localStorage.setItem("user", response.data);
      // window.user = response.data;
      API.setUserStorage(response.data);
      // window.getApp.$emit("APP_SAIDAS_CLIENTE_GET");
      this.loading = false;
      this.$router.push("/" + route);
    });
    // console.log(token);
  },
};
</script>
