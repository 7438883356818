import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialogStatusNfe: false,
    dialogDistribuirNFeCliente: false,
    dialogConsultaRetornoDoLoteNFeByNfe: false,
    dialogCategoria: false,
    dialogItem: false,
    dialogInventario:[],
    dialogEntrada: false,
    dialogSaida:false,
    dialogRelatorioSaida:false,
    dialogFornecedor:false,
    dialogResumoDiario:false,
    dialogCliente:false,
    dialogMTR:false,
    dialogFotoCliente:false,
    dialogDanfe:false,
    dialogPrecoPadrao:false,
    dialogUser: false,
    dialogPermissao: false,
    dialogPerfil: false,
    dialogPerfilUser: false,
    metodoPagamento: [],
    languages:[
      {
        label:'Português',
        flag:'https://cdn.vuetifyjs.com/images/flags/br.png',
        locale:'pt'
      },
      {
        label:'Español',
        flag:'https://cdn.vuetifyjs.com/images/flags/mx.png',
        locale:'es'
      },
    ],
    locale:{
      label:'Português',
      flag:'https://cdn.vuetifyjs.com/images/flags/br.png',
      locale:'pt'
    },
    statusNfeRetorno: {
      xMotivo: '',
      cStat: ''
    },
    statusNfeTitulo: '',
    users: [],
    userEdit: {
      setor: {}
    },
    permissionEdit: {

    },
    user: {
      token: false
    },
    resumoDia:[],
    unidades: [],
    itens: [],
    itensSucata: [],
    setores: [],
    compradores: [],
    fornecedores:[],
    planejadores:[],
    clientes:[],
    clientesEspanha:[],
    clientesPendentes:[],
    planejamentos:[],
    clientesbalanca:[],
    tiposucata:[],
    tiposucatasaida:[],
    tiposfrete:[],
    funcionarios:[],
    precoMedioAtual: '0',
    estoqueFisico: '0',
    estoqueContabil: '0',
    saidasCliente: [],
    saidasClienteQuantidade: [],
    pesoSaidasCliente: [],
    pesoSaidaEntrada: [],
    estoque: [],
    precoPadrao: [],
    precoPadraoSaida: [],
    categoria: {},
    item: {},
    complexPlanejamento: {
      selected: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Cliente",
          value: "cliente.name",
        },
        {
          text: "Paciente",
          value: "paciente",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Usuário",
          value: "user.name",
        },
        {
          text: "Entrega",
          value: "data_entrega",
        },
        {
          text: "Ações",
          value: "",
        },
      ],
      headersCredenciado: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Paciente",
          value: "paciente",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Usuário",
          value: "user.name",
        },
        {
          text: "Entrega",
          value: "data_entrega",
        },
        {
          text: "Ações",
          value: "",
        },
      ],
      items: [],
    },
    complexArquivos: {
      selected: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Tamanho",
          value: "tamanho",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Caminho",
          value: "path",
        },
        {
          text: "Criação",
          value: "created_at",
        },
        {
          text: "Download",
          value: "",
        },
      ],
      items: [],
    },
    complexPlanos: {
      selected: [],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Plano Sugerido",
          value: "plano_sugerido",
          align: "center",
        },

        {
          text: "Escolha",
          value: "",
        },
      ],
      items: [],
    },
    teste: 0,
    entrada:{},
    fornecedor:{},
    cliente:{},
    planejamento:{},
    mtr:{},
    danfe:{},
    precoPadraoItem:{
      CdItem: 111
    },
    saida:{},
    urlBase: 'https://api.alignerplan.com.br/api/',
    mostrarAvatar:true,
    urlBaseWeb: 'https://api.alignerplan.com.br/',
    CdLoja: 1,
    loja:{},
    eventos:[],
    ccustos:[],
    apuracaoICMS:[
      {descricao:'Crédito do Imposto',valor:1},
      {descricao:'Isentas ou não Tributadas',valor:2},
      {descricao:'Outras',valor:2},
    ]
  },
  mutations: {
    dialogStatusNfe (state, value) {
      state.dialogStatusNfe = value
    },
    statusNfeRetorno (state, value) {
      state.statusNfeRetorno = value
    },
    statusNfeTitulo (state, value) {
      state.statusNfeTitulo = value
    },
    setUser (state, value) {
      state.user = value
    },
    abrirModalEntrada(state, value) {
      state.entrada = value
      state.dialogEntrada = true
    },
    abrirModalFornecedor(state, value) {
      state.fornecedor = value
      state.dialogFornecedor = true
    },
    abrirModalResumoDiaro(state) {
      state.dialogResumoDiario = true
    },
    abrirModalCliente(state, value) {
      state.cliente = value
      state.mostrarAvatar=true
      state.dialogCliente = true
    },
    abrirModalPlanejamento(state, value) {
      state.planejamento = value
      state.dialogPlanejamento = true
    },
    abrirModalMTR(state, value) {
      state.mtr = value
      state.dialogMTR = true
    },
    abrirModalViewMTR(state, value) {
      state.mtr = value
      state.dialogViewMTR = true
    },
    abrirModalDanfe(state, value) {
      state.danfe = value
      state.dialogDanfe = true
    },
    abrirModalPermissoes(state, value) {
      state.permissionEdit = value
      state.dialogPermissao = true
    },
    abrirModalPrecoPadrao(state, value) {
      state.precoPadraoItem = value
      state.dialogPrecoPadrao = true
    },
    fecharModalPrecoPadrao(state) {
      state.precoPadraoItem = {
        CdItem: 111
      }
      state.dialogPrecoPadrao = false
    },
    abrirModalSaida(state, value) {
      state.saida = value
      state.dialogSaida = true
    },
    fecharModalEntrada(state) {
      state.entrada = {}
      state.dialogEntrada = false
    },
    fecharModalResumoDiario(state) {
      state.dialogResumoDiario = false
    },
    fecharModalFornecedor(state) {
      state.fornecedor = {}
      state.dialogFornecedor = false
    },
    fecharModalCliente(state) {
      state.cliente = {}
      state.dialogCliente = false
    },
    fecharModalPlanejamento(state) {
      state.planejamento = {}
      state.dialogPlanejamento = false
    },
    fecharModalMTR(state) {
      state.mtr = {}
      state.dialogMTR = false
    },
    fecharModalFotoCliente(state) {
      state.cliente.logo = ''
      state.dialogFotoCliente = false
    },
    fecharModalViewMTR(state) {
      state.mtr = {}
      state.dialogViewMTR = false
    },
    fecharModalDanfe(state) {
      state.danfe = {}
      state.dialogDanfe = false
      state.danfe.exibirPDF = false;
    },
    fecharModalRelatorioSaida(state){
      state.dialogRelatorioSaida = false
    },
    fecharModalSaida(state) {
      state.saida = {}
      state.dialogSaida = false
    },
    fornecedores(state, value) {
      state.fornecedores = value
    },
    funcionarios(state, value) {
      state.funcionarios = value
    },
    itens(state, value) {
      state.itens = value
    },
    itensSucata(state, value) {
      state.itensSucata = value
    }
  },
  actions: {

  },
  getters: {
    user: state => {
      return state.user
    },
    dialogEntrada: state => {
      return state.dialogEntrada
    },
    dialogSaida: state => {
      return state.dialogSaida
    },
    entrada: state => {
      return state.entrada
    },
    saida: state => {
      return state.saida
    },
    fornecedores: state => {
      return state.fornecedores
    },
    tiposucata: state => {
      return state.tiposucata
    },
    tiposucatasaida: state => {
      return state.tiposucatasaida
    },
    clientesbalanca: state => {
      return state.clientesbalanca
    },
    tiposfrete: state => {
      return state.tiposfrete
    },
    funcionarios: state => {
      return state.funcionarios
    },
    itens: state => {
      return state.itens
    },
    itensSucata: state => {
      return state.itensSucata
    },
    saidasCliente: state => {
      return state.saidasCliente
    },
    saidasClienteQuantidade: state => {
      return state.saidasClienteQuantidade
    },
    compradores: state => {
      return state.compradores
    },
    exibirPDF: state => {
      return state.danfe.exibirPDF
    }
  }
})
