//import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import './plugins/axios'
import './theme/default.styl'
import VeeValidate from 'vee-validate'
import 'font-awesome/css/font-awesome.css'
import 'nprogress/nprogress.css'
import './plugins/vuetify'
import Truncate from 'lodash.truncate'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './registerServiceWorker'
import 'moment/locale/pt-br'
import vuetify from './plugins/vuetify';
import vmoney from './plugins/vmoney';
import VueSession from 'vue-session'
//import './lang/index'
import i18n from './i18n'

Vue.use(VueSession)

Vue.config.productionTip = false
Vue.prototype.$http = axios

Vue.filter('truncate', Truncate)
Vue.use(VeeValidate, { fieldsBagName: 'formFields' })
new Vue({
  router,
  store,
  vuetify,
  vmoney,
  i18n,
  render: h => h(App)
}).$mount('#app')
