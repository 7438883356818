var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"lg3":"","sm6":"","xs12":""}},[_c('mini-statistic',{attrs:{"icon":"verified","title":String(_vm.indicadores.aprovado),"sub-title":"Planejamentos Aprovados","color":"green"}})],1),_c('v-flex',{attrs:{"lg3":"","sm6":"","xs12":""}},[_c('mini-statistic',{attrs:{"icon":"edit_note","title":String(_vm.indicadores.producao),"sub-title":"Planejamentos Em Produção ou Edição","color":"blue lighten-2"}})],1),_c('v-flex',{attrs:{"lg3":"","sm6":"","xs12":""}},[_c('mini-statistic',{attrs:{"icon":"mdi-account-group","title":String(_vm.indicadores.revisao),"sub-title":"Planejamentos Em Revisão","color":"orange"}})],1),_c('v-flex',{attrs:{"lg3":"","sm6":"","xs12":""}},[_c('mini-statistic',{attrs:{"icon":"mdi-account-group","title":String(_vm.indicadores.aguardando),"sub-title":"Planejamentos Aguardando...","color":"red"}})],1)],1),_c('v-layout',{staticClass:"mt-3",attrs:{"row":"","wrap":""}},[_vm._l((_vm.devolvidoArquivo),function(item,index){return [_c('v-flex',{key:item.id,attrs:{"xs12":"","lg12":""}},[_c('v-alert',{attrs:{"outline":"","color":"error","icon":"warning","value":true}},[_c('v-layout',{attrs:{"row":"","pa-0":""}},[_c('v-flex',{directives:[{name:"ripple",rawName:"v-ripple"}],staticStyle:{"line-height":"50px"},attrs:{"align-space-between":"","xs10":"","pa-0":"","ma0":""}},[_c('b',[_vm._v("Planejamento:")]),_vm._v(" "+_vm._s(item.id)+" - "),_c('b',[_vm._v("Status:")]),_vm._v(" "+_vm._s(item.status)+" - "),_c('b',[_vm._v("Motivo:")]),_vm._v(" "+_vm._s(item.motivo)+" ")]),_c('v-flex',{staticClass:"text-right",attrs:{"align-end":"","justify-end":"","xs2":"","pa-0":"","ma0":""}},[(
                      _vm.user.tipo === 4 &&
                      (item.status === 'Planejamento em produção' ||
                        item.status === 'Devolvido para Revisão' ||
                        item.status === 'Arquivos desaprovados' ||
                        item.status === 'Arquivos em análise' ||
                        item.status === 'Planejamento em edição')
                    )?_c('planejamento-view',{attrs:{"planejamento":item,"colorButton":"red darken-4"}}):_vm._e()],1)],1)],1)],1)]}),_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-card',{staticClass:"white--text",attrs:{"color":"grey darken-3"}},[_c('v-layout',[_c('v-flex',{staticClass:"text-center red--text",attrs:{"xs4":""}},[_c('v-avatar',{attrs:{"color":"transparent","size":"150"}},[_c('v-icon',{attrs:{"dark":"","size":"150"}},[_vm._v("post_add")])],1)],1),_c('v-flex',{attrs:{"xs8":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"display-1"},[_vm._v("Submeta um caso")]),_c('span',{staticClass:"body-1"},[_vm._v("Submeta um caso clínico para que nosso laboratório possa confeccionar os Alinhadores Invisíveis.")])])])],1)],1),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","dark":"","to":"submeta"}},[_vm._v("Acessar")])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","lg6":""}},[_c('v-card',{staticClass:"white--text",attrs:{"color":"grey darken-3"}},[_c('v-layout',[_c('v-flex',{staticClass:"text-center red--text",attrs:{"xs4":""}},[_c('v-avatar',{attrs:{"color":"transparent","size":"150"}},[_c('v-icon',{attrs:{"dark":"","size":"150"}},[_vm._v("folder_shared")])],1)],1),_c('v-flex',{attrs:{"xs8":""}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{staticClass:"display-1"},[_vm._v("Seus Pacientes")]),_c('span',{staticClass:"body-1"},[_vm._v("Aqui você encontrará os relatórios e visualizadores de seus pacientes, acompanhará o processo de envio de planejamentos.")])])])],1)],1),_c('v-divider',{attrs:{"light":""}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","dark":"","to":"pacientes"}},[_vm._v("Acessar")])],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }